<template>
  <div>
    <div class="catalog-grid">
      <div>
        <TradexPlusMembers
          v-if="!loading && exhibitors.filter((c) => c.isTradexPlusMember).length > 0"
          :companies="exhibitors.filter((c) => c.isTradexPlusMember)"
          :tradexPlusLogo="tradexPlusLogo"
          :tradexPlusBackground="tradexPlusBackground" />

        <Content v-if="!loading" :companyGroups="companyGroups"  @selectCard="onCardSelect" />

        <div v-if="loading" class="text-center my-5">
          <div class="loading-indicator" />
        </div>
      </div>

      <div class="controls-column">
        <div class="sticky-wrapper">
          <div class="label">
            Event
          </div>
          <select @change="(e) => onEventSelect(parseInt(e.target.value))" class="form-control">
            <option v-for="event in eventSwitcherItems" :key="event.id" :value="event.virtualFairId" :selected="selectedEvent.id === event.id">
              {{ event.name }}
            </option>
          </select>

          <div class="label">
            Group By
          </div>
          <select @change="(e) => onGroupingSelect(e.target.value)" class="form-control mb-3">
            <option :selected="groupBy === grouping.NAME" :value="grouping.NAME">
              Names
            </option>
            <option :selected="groupBy === grouping.CATEGORY" :value="grouping.CATEGORY">
              Categories
            </option>
          </select>

          <div v-if="!loading && currentVirtualFair" class="nav">
            <NavAlphabet v-if="groupBy === grouping.NAME" :groups="companyGroups.map((cg) => cg.group)" />
            <NavCategory v-if="groupBy === grouping.CATEGORY" :groups="companyGroups.map((cg) => cg.group)" />
          </div>
        </div>
      </div>
    </div>

    <DetailOverlay
      v-if="selectedExhibitorProfile"
      :exhibitorProfile="selectedExhibitorProfile"
      :virtualFairSlug="currentVirtualFair ? currentVirtualFair.slug : null"
      @closeOverlay="onOverlayClose" />
  </div>
</template>

<script>
import { getAlphabetizedGroups, getCategorizedGroups } from '../util/grouping'
import { API_BASE_URL, parseEvents, parseExhibitorListData, parseExhibitorProfileData } from '../util/api'
import { grouping } from '../util/constants'
import Content from './Content.vue'
import NavAlphabet from './NavAlphabet.vue'
import NavCategory from './NavCategory.vue'
import DetailOverlay from './DetailOverlay.vue'
import TradexPlusMembers from './TradexPlusMembers.vue'

export default {
  name: 'Catalog',
  components: {
    Content,
    NavAlphabet,
    NavCategory,
    DetailOverlay,
    TradexPlusMembers,
  },
  props: {
    tradexPlusLogo: {
      type: String,
      required: true,
    },
    tradexPlusBackground: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    allVirtualFairs: [],
    eventSwitcherItems: [],
    exhibitors: [],
    currentVirtualFair: null,
    selectedEvent: null,
    selectedExhibitorProfile: null,
    grouping,
    groupBy: grouping.NAME,
  }),
  computed: {
    companyGroups: function () {
      if (this.groupBy === grouping.NAME) {
        const [alphabetizedGroups, numberGroup] = getAlphabetizedGroups(this.exhibitors)
        return [numberGroup, ...alphabetizedGroups].filter((g) => g)
      }

      if (this.groupBy === grouping.CATEGORY) {
        return getCategorizedGroups(this.exhibitors)
      }

      return []
    },
    isMobile: function () {
      return window.innerWidth <= 638
    }
  },
  methods: {
    async onCardSelect ({ companyId: exhibitorId }) {
      if (exhibitorId) {
        try {
          this.selectedExhibitorProfile = parseExhibitorProfileData(await (await fetch(`${API_BASE_URL}/exhibitor/${exhibitorId}/profile`)).json())
        } catch (err) {
          // TODO; handle error
        }
      }
    },
    onOverlayClose () {
      this.selectedExhibitorProfile = null
    },
    onGroupingSelect (groupBy = grouping.NAME) {
      this.groupBy = groupBy
    },
    async onEventSelect (virtualFairId) {
      console.log(virtualFairId)
      if (virtualFairId) {
        const virtualFair = this.allVirtualFairs.find((vf) => vf.id === virtualFairId)

        if (virtualFair && virtualFair.events) {
          this.loading = true

          try {
            await this.loadCompanies(virtualFair.events.map((e) => e.id))
          } catch (err) {
            // TODO; handle error
          }

          // Workaround to get the slug for direct booth linking
          this.currentVirtualFair = virtualFair

          this.loading = false
        }
      }
    },
    async loadCompanies (eventIds = []) {
      this.exhibitors = []

      // Wrap im promise to be able to await company fetching
      return new Promise(async (resolve, reject) => {
        try {
          for (const eventId of eventIds) {
            const exhibitorList = (await (await fetch(`${API_BASE_URL}/event/${eventId}/exhibitorprofiles/?format=json`)).json()) // TODO change to exhibitor profile list
              .map(parseExhibitorListData)

            this.exhibitors = [
              ...this.exhibitors,
              ...exhibitorList,
            ]
          }

          this.exhibitors = this.exhibitors.sort((c1, c2) => c1.name.localeCompare(c2.name))

          resolve()
        } catch (err) {
          reject(err)
        }
      })
    },
  },
  created: async function () {
    this.loading = true

    // Load all virtual fairs
    this.allVirtualFairs = (await (await fetch(`${API_BASE_URL}/virtualfair/?format=json`)).json()).filter((vf) => vf.has_catalog)

    // Parse all events for event switcher
    this.eventSwitcherItems = this.allVirtualFairs
      .flatMap(parseEvents)
      .sort((e1, e2) => e1.startDateEpoch < e2.startDateEpoch)
    if (this.eventSwitcherItems && this.eventSwitcherItems.length > 0) {
      this.selectedEvent = this.eventSwitcherItems[0]
      this.onEventSelect(this.selectedEvent.virtualFairId)
    }

    // If a virtual fair id is passed as a query param, filter the virtual fairs based on it
    const urlParams = new URLSearchParams(window.location.search)
    const virtualFairIdString = urlParams.get('virtualFairId')

    if (virtualFairIdString) {
      const virtualFairId = parseInt(virtualFairIdString)
      const filteredVirtualFairs = this.allVirtualFairs.filter((vf) => vf.id === virtualFairId)

      // Load companies based on filtered (possibly) virtual fairs
      try {
        await this.loadCompanies(filteredVirtualFairs.flatMap(parseEvents).map((e) => e.id))
      } catch (err) {
        // TODO; handle error
      }
    }

    // Open company overlay when a company id is passed as query param
    const companyIdString = urlParams.get('companyId')

    if (companyIdString) {
      const companyId = parseInt(companyIdString)
      const company = this.exhibitors.find((c) => c.companyId === companyId)

      if (company) {
        this.onCardSelect({ companyId: company.id })
      }
    }

    this.loading = false
  }
}
</script>

<style scoped>
.catalog-grid {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 20px;
}

@media (max-width: 755px) {
  .catalog-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 755px) {
  .catalog-grid .controls-column {
    /* Put the form column to the first position on small screens */
    grid-row-start: 1;
  }
}

.catalog-grid .controls-column .sticky-wrapper {
  position: sticky;
  top: 84px;
  background-color: #B3E5FC;
  border-radius: 3px;
  padding: 10px;
  box-sizing: border-box;
}

.catalog-grid .controls-column .sticky-wrapper .label {
  font-size: .9rem;
  font-weight: 500;
  margin-bottom: 3px;
}

.catalog-grid .controls-column .sticky-wrapper select {
  width: 100%;
  margin-bottom: 10px;
  padding-left: 8px;
}

.catalog-grid .controls-column .sticky-wrapper .nav {
  max-height: calc(100vh - 500px);
  overflow-y: auto;
}
</style>
