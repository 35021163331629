<template>
  <div style="padding-top: 10px;">
    <!-- LEVEL 1 -->
    <div v-for="category1 in parsedCategories" style="padding-left: 20px;" :key="category1.id">
      <input type="checkbox" :ref="category1.id" :id="category1.id" name="categories" :value="category1.id" @change="handleCheckboxClick" :checked="checkedCategoryIds.includes(category1.id)">
      <label :for="category1.id">{{ category1.label }}</label>

      <!-- LEVEL 2 -->
      <div v-if="checkedCategoryIds.includes(category1.id)">
        <div v-for="category2 in category1.children" style="padding-left: 20px;" :key="category2.id">
          <input type="checkbox" :ref="category2.id" :id="category2.id" name="categories" :value="category2.id" @change="handleCheckboxClick" :checked="checkedCategoryIds.includes(category2.id)">
          <label :for="category2.id">{{ category2.label }}</label>

          <!-- LEVEL 3 -->
          <div v-if="checkedCategoryIds.includes(category2.id)">
            <div v-for="category3 in category2.children" style="padding-left: 20px;" :key="category3.id">
              <input type="checkbox" :ref="category1.id" :id="category3.id" name="categories" :value="category3.id" @change="handleCheckboxClick" :checked="checkedCategoryIds.includes(category3.id)">
              <label :for="category3.id">{{ category3.label }}</label>

              <!-- LEVEL 4 -->
              <div v-if="checkedCategoryIds.includes(category3.id)">
                <div v-for="category4 in category3.children" style="padding-left: 20px;" :key="category4.id">
                  <input type="checkbox" :ref="category4.id" :id="category4.id" name="categories" :value="category4.id" @change="handleCheckboxClick" :checked="checkedCategoryIds.includes(category4.id)">
                  <label :for="category4.id">{{ category4.label }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'category-tree',
  props: {
    categories: String, // Needs to be parsed to json (see created method)
    selectedCategories: String, // Needs to be parsed to json (see created method)
  },
  data: function () {
    return {
      parsedCategories: [],
      parsedSelectedCategories: [],
      checkedCategoryIds: [],
    }
  },
  methods: {
    handleCheckboxClick: function (e) {
      const id = parseInt(e.target.id, 10);

      if (!isNaN(id)) {
        if (e.target.checked) {
          this.checkedCategoryIds = [...this.checkedCategoryIds, id]
        } else {
          this.checkedCategoryIds = [...this.checkedCategoryIds].filter((ccid) => ccid !== id)
        }
      }
    },
    isExpanded: function (categoryId) {
      return this.checkedCategoryIds.includes(categoryId.toString())
    },
    initCheckboxes: function (categories = []) {
      if (categories.length === 0) {
        return
      }

      return categories.map((category) => {
        if (this.parsedSelectedCategories.includes(category.id)) {
          // Add to checked category ids to update checkbox state
          this.checkedCategoryIds = [...this.checkedCategoryIds, category.id]
          return this.initCheckboxes(category.children)
        }
      })
    }
  },
  created () {
    try {
      if (this.categories) {
        const maybeValidJsonString1 = this.categories.replace(/'/gi, '"')
        this.parsedCategories = JSON.parse(maybeValidJsonString1)
      }

      if (this.selectedCategories) {
        const maybeValidJsonString2 = this.selectedCategories.replace(/'/gi, '"')
        this.parsedSelectedCategories = JSON.parse(maybeValidJsonString2)
      }
    } catch (err) {
      console.error('Unable to parse categories to JSON.', err)
    }
  },
  mounted () {
    this.initCheckboxes(this.parsedCategories)
  }
}
</script>
