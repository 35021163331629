<template>
  <div
    v-bind:style="mergedWrapperStyle"
    v-bind:class="wrapperClass">
    <div
      ref="dropdownTrigger"
      v-on:click="handleButtonClick"
      v-bind:style="mergedTriggerWrapperStyle"
      v-bind:class="triggerWrapperClass">
      <slot name="dropdown-trigger">
        <div>Dropdown Trigger</div>
      </slot>
    </div>
    <div
      ref="dropdownContent"
      v-if="expanded"
      v-bind:style="mergeContentdWrapperStyle"
      v-bind:class="contentWrapperClass">
      <slot name="dropdown-content">
        <div>Dropdown Content</div>
      </slot>
    </div>
  </div>
</template>

<script>
import EventBus from '../vendors/event-bus';

export default {
  name: 'dropdown',
  props: {
    wrapperStyle: Object,
    triggerWrapperStyle: Object,
    contentWrapperStyle: Object,
    wrapperClass: String,
    triggerWrapperClass: String,
    contentWrapperClass: String,
    right: Boolean,
    expand: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  mounted() {
    if (this.expand){
      this.expanded = true;
    }
  },
  data: function () {
    return {
      expanded: false,
      baseWrapperStyle: {
        boxSizing: 'border-box',
        position: 'relative',
        display: 'inline-block',
        verticalAlign: 'top',
      },
      baseTriggerWrapperStyle: {
        boxSizing: 'border-box',
      },
      baseContentWrapperStyle: {
        boxSizing: 'border-box',
        position: 'absolute',
        zIndex: 1,
        backgroundColor: 'white',
        marginTop: '3px',
        padding: '12px',
        borderRadius: '3px',
        boxShadow: '1px 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
      }
    }
  },
  computed: {
    mergedWrapperStyle: function () {
      return {
        ...this.baseWrapperStyle,
        ...this.wrapperStyle,
      }
    },
    mergedTriggerWrapperStyle: function () {
      return {
        ...this.baseTriggerWrapperStyle,
        ...this.triggerWrapperStyle,
      }
    },
    mergeContentdWrapperStyle: function () {
      const right = this.right ? '0' : 'auto'

      return {
        right,
        ...this.baseContentWrapperStyle,
        ...this.contentWrapperStyle,
      }
    }
  },
  methods: {
    handleButtonClick: function () {
      if (!this.expanded) {
        this.expanded = true
        document.addEventListener('click', this.handleDropdown)
      } else {
        this.expanded = false
        document.removeEventListener('click', this.handleDropdown)
      }

      this.$nextTick(() => {
        EventBus.$emit('dropdown', { expanded: this.expanded });
      })
    },
    handleDropdown: function (e) {
      /**
       * Compare elements that were clicked with ref nodes and show/hide the
       * dropdown accordingly. Because we're using slots we need to access the
       * firstChild of the dropdown trigger. Also the dropdown shouldn't
       * collapse if the click happens within the dropdown content.
       */
      if (
        e.target !== this.$refs.dropdownTrigger.firstChild &&
        this.$refs.dropdownContent &&
        !this.$refs.dropdownContent.contains(e.target)
      ) {
        this.expanded = false
      }
    }
  }
}
</script>
