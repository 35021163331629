<template>
  <ul>
    <li :style="`color: ${category.color || 'black'}; font-weight: ${category.color ? '500' : '400'};`">{{ category.name }}
      <category-tree-showguide-item v-for="item in category.children" :key="item.id" :category="item" />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'category-tree-showguide-item',
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
ul {
  padding-left: 20px;
}
</style>
