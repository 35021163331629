const API_BASE_URL = 'https://api.tradex-services.com'

const parseEvents = (virtualFair) => virtualFair.events.map((e) => ({
  id: e.id,
  name: e.name,
  virtualFairId: virtualFair.id,
  startDateEpoch: new Date(e.start_date).getTime()
}));

const parseExhibitorListData = (data) => ({
  id: data.id,
  companyId: data.company_id,
  name: data.company_name,
  logo: data.logo ?`${API_BASE_URL}${data.logo}` : null,
  isTradexPlusMember: data.tradex_plus,
  tags: data.tags.map((t) => t.name),
})

const parseExhibitorProfileData = (data) => ({
  id: data.id,
  companyId: data.company_id,
  website: data.website,
  email: data.e_mail,
  phone: data.phone,
  logo: data.logo ?`${API_BASE_URL}${data.logo}` : null,
  name: data.name,
  isTradexPlusMember: data.tradex_plus,
  street: data.address.street,
  zipCode: data.address.zip_code,
  city: data.address.city,
  country: data.address.country,
  isCoExhibitor: data.is_co_exhibitor,
  boothNumber: data.booth[0].booth_number,
  description: data.description,
  tags: data.tags.map((t) => t.name),
})

export {
  API_BASE_URL,
  parseEvents,
  parseExhibitorListData,
  parseExhibitorProfileData,
}
