<template>
  <div class="overlay-wrapper" @click.self="$emit('closeOverlay')">
    <div class="content">
      <div class="detail-row">
        <div class="image-wrapper">
          <ImageItem v-if="exhibitorProfile.logo" :source="exhibitorProfile.logo" :alt="`${exhibitorProfile.name} logo`" />
        </div>
        <div class="booth-number">
          Stand No.:
          <a
            v-if="virtualFairSlug"
            :href="`https://maps.tradex-services.com/map/${virtualFairSlug}?booth=${exhibitorProfile.boothNumber}`"
            target="_blank">
            {{ exhibitorProfile.boothNumber }}
          </a>
          <span v-if="!virtualFairSlug">{{ exhibitorProfile.boothNumber }}</span>
        </div>
      </div>

      <div v-html="exhibitorProfile.description" />
      <div class="detail-row">
        <div>
          <div>{{ exhibitorProfile.name }}</div>
          <div>{{ exhibitorProfile.street }}</div>
          <div>{{ exhibitorProfile.zipCode }} {{ exhibitorProfile.city }}</div>
          <div>{{ exhibitorProfile.country }}</div>
        </div>

        <div>
          <div>Phone: {{ exhibitorProfile.phone }}</div>
          <div>E-Mail: {{ exhibitorProfile.email }}</div>
          <div>Website: <a :href="exhibitorProfile.website" :alt="`${exhibitorProfile.name} logo`" target="_blank">{{
              exhibitorProfile.website
            }}</a></div>
        </div>
      </div>

      <div class="close" @click.prevent="$emit('closeOverlay')">&#10005;</div>
    </div>
  </div>
</template>

<script>
import ImageItem from './ImageItem.vue'

export default {
  name: 'DetailOverlay',
  components: {
    ImageItem,
  },
  props: {
    exhibitorProfile: {
      type: Object,
      required: true,
    },
    virtualFairSlug: {
      type: String,
      required: false,
    }
  }
}
</script>

<style scoped>
.overlay-wrapper {
  display: grid;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  z-index: 1030;

  background-color: rgba(0, 0, 0, .5);
}

.overlay-wrapper .content {
  position: relative;
  margin: auto;

  max-width: 1000px;
  background-color: white;
  border-radius: 3px;
  padding: 20px;

  max-height: 100%;
  overflow-y: auto;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.overlay-wrapper .content .detail-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

@media (max-width: 755px) {
  .overlay-wrapper .content .detail-row {
    grid-template-columns: 1fr;
  }
}

.overlay-wrapper .content .image-wrapper {
  height: 200px;
  max-height: 200px;
}

.overlay-wrapper .content .booth-number {
  align-self: center;
  justify-self: end;
  font-size: 1.4rem;
  font-weight: 500;
}

.overlay-wrapper .close {
  position: absolute;
  top: 10px;
  right: 12px;
  font-weight: 800;
  font-size: 2rem;
  cursor: pointer;
}
</style>
