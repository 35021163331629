<template>
  <div>
    <v-photoswipe-gallery ref="photoSwipe" :isOpen="isOpenGallery" :options="optionsGallery"
                          :items="items">
      <img slot-scope="props" v-if="props.item.thumbSrc" :src="props.item.thumbSrc" alt="picture" loading="lazy" />
    </v-photoswipe-gallery>
    <v-photoswipe :isOpen="isOpen" :items="items" :options="options" @close="hidePhotoSwipe"></v-photoswipe>
  </div>
</template>

<script>
import { PhotoSwipe, PhotoSwipeGallery } from 'v-photoswipe';

export default {
  name: 'gallery',
  props: {
    items: Array,
  },
  components: {
    'v-photoswipe': PhotoSwipe,
    'v-photoswipe-gallery': PhotoSwipeGallery,
  },
  data: function () {
    return {
      isOpen: false,
      isOpenGallery: false,
      options: {
        index: 0,
      },
      optionsGallery: {
        shareEl: false,
        fullscreenEl: false,
        bgOpacity: '0.9',
      }
    }
  },
  methods: {
    showPhotoSwipe(index) {
      this.isOpen = true
      this.$set(this.options, 'index', index)
    },
    hidePhotoSwipe() {
      this.isOpen = false
    },
  },
};
</script>
