<template>
  <div>
    <div v-on:click="toggleModalTrigger">
      <slot name="modal-trigger">
        <div>Modal Trigger</div>
      </slot>
    </div>
    <div v-if="visible" @click="toggleModal" class="overlay">
      <div v-on:click="stopPropagation" class="content-wrapper">
        <div @click="toggleModal" class="close">&#10005;</div>
        <slot name="modal-content">
          <div>Modal Content</div>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'modal',
  props: {
    openModal: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      visible: false,
    }
  },
  methods: {
    handleEnter: function (e) {
      // if (e.keyCode === 13){
      //   e.preventDefault()
      //   e.stopPropagation()
      // }
    },
    toggleModalTrigger: function(e) {
      if (!this.visible){
        this.visible = true;
        e.preventDefault()
      }
    },
    toggleModal: function (e) {
      e.preventDefault()
      this.visible = !this.visible;
    },
    stopPropagation: function (e) {
      e.stopPropagation();
    }
  },
  mounted() {
    if(this.openModal === true){
      this.visible = true;
    }
  }
}
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 1030; /* For use in vf-fair */
  padding: 20px;

  display: grid;
  align-items: center;
  justify-items: center;
}

.content-wrapper {
  position: relative;
  max-width: 600px;
  padding: 20px;
  background-color: white;
  border-radius: 3px;
  max-height: calc(100vh - 90px);
  overflow-y: auto;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
</style>
