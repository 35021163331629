<template>
  <div class="auto-complete-wrapper">
    <input
      type="text"
      class="form-control"
      v-model="inputValue"
      @focus="handleFocus"
      @blur="handleBlur"
      @input="handleInput"
      :required="required"
      :placeholder="placeholder" />

    <div v-if="inputValue.length > 0" @click="clearInput" class="close">&#10005;</div>

    <input type="hidden" :name="name" :value="selectedCompanyId" />

    <div v-if="searching" class="results">
      <div v-for="company in companies" :key="company.id" @click="selectCompany(company)" class="result-item">
        {{ company.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'auto-complete-input',
  props: {
    requestUrl: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: function () {
    return {
      searching: false,
      inputValue: '',
      companies: [],
      selectedCompanyId: '',
      selectedCompanyName: ''
    }
  },
  methods: {
    handleFocus: function () {
      if (this.inputValue) {
        this.searching = true
      }
    },
    handleBlur: function () {
      if (!this.inputValue) {
        this.searching = false
      }
    },
    handleInput: async function () {
      if (this.inputValue) {
        this.searching = true

        try {
          const res = await fetch(`/${this.requestUrl}?q=${this.inputValue}`)
          const json = await res.json()
          this.companies = json.results
        } catch (err) {
          console.error('Unable to fetch companies for auto complete', err)
        }
      }
    },
    clearInput: function () {
      this.inputValue = ''
      this.searching = false
    },
    selectCompany: function (company) {
      this.selectedCompanyId = company.id
      this.selectedCompanyName = company.text

      this.inputValue = company.text
      this.searching = false
    }
  }
}
</script>

<style scoped>
.auto-complete-wrapper {
  position: relative;
}

.auto-complete-wrapper input {
  width: 100%;
}

.auto-complete-wrapper .results {
  position: absolute;
  top: 41px;
  left: 1px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  padding: 6px 0;
  min-width: 160px;
}

.auto-complete-wrapper .results .result-item {
  padding: 5px 10px;
  cursor: pointer;
  color: #008bc6;
}

.auto-complete-wrapper .results .result-item:hover {
  padding: 5px 10px;
  cursor: pointer;
  color: #00a0da;
  background-color: rgb(248, 249, 250);
}

.auto-complete-wrapper .close {
  position: absolute;
  top: 8px;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
</style>
