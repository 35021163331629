import request from '../../components/virtualfair/request.js'
import config from '../../components/virtualfair/config.js'

// getter names
export const COMPANIES = 'COMPANIES/COMPANIES'
export const SELECTED = 'COMPANIES/SELECTED'
export const SELECTED_EXHIBITOR = 'COMPANIES/SELECTED_EXHIBITOR'
export const PRODUCTS = 'COMPANIES/PRODUCTS'
export const PRODUCT = 'COMPANIES/PRODUCT'

// action names
export const FETCH_COMPANY = 'COMPANIES/FETCH_COMPANY'
export const FETCH_EXHIBITOR = 'COMPANIES/FETCH_EXHIBITOR'
export const FETCH_EXHIBITOR_DETAILS = 'COMPANIES/FETCH_EXHIBITOR_DETAILS'
export const FETCH_COMPANY_DETAILS = 'COMPANIES/FETCH_COMPANY_DETAILS'
export const FETCH_EXHIBITOR_PRODUCTS = 'COMPANIES/FETCH_EXHIBITOR_PRODUCTS'

// mutation names
export const CLEAR_PRODUCT = 'COMPANIES/CLEAR_PRODUCT'
export const CLEAR_COMPANY = 'COMPANIES/CLEAR_COMPANY' // todo change name
export const SET_COMPANIES = 'COMPANIES/SET_COMPANIES'
export const SET_PRODUCTS = 'COMPANIES/SET_PRODUCTS'
export const SET_PRODUCT = 'COMPANIES/SET_PRODUCT'
export const SET_SELECTED = 'COMPANIES/SET_SELECTED'
export const SET_SELECTED_EXHIBITOR = 'COMPANIES_SET_SELECTED_EXHIBITOR'
export const SHIFT_IMAGE = 'COMPANIES/SHIFT_IMAGE'

const state = {
  selected: {},
  selectedExhibitor: {},
  companies: [],
  products: [],
  product: null
}

const getters = {
  [SELECTED]: state => state.selectedExhibitor, // todo change name to exhibitor
  [SELECTED_EXHIBITOR]: state => state.selectedExhibitor, // todo make this normal
  [COMPANIES]: state => state.companies,
  [PRODUCTS]: state => state.products,
  [PRODUCT]: state => state.product
}

const actions = {
  async [FETCH_EXHIBITOR] ({ dispatch }, {exhibitorId}) {
    await Promise.all([
      dispatch(FETCH_EXHIBITOR_DETAILS, exhibitorId),
      dispatch(FETCH_EXHIBITOR_PRODUCTS, exhibitorId)
    ])
  },

  async [FETCH_EXHIBITOR_DETAILS] ({ commit }, id) {
    try {
      const companyDetails = await request(`${config.baseUrl}/exhibitor-detail/${id}`)
      commit(SET_SELECTED_EXHIBITOR, companyDetails)
    } catch (err) {
      if (err.response.status === 404){
        commit(SET_SELECTED_EXHIBITOR, {})
      }
    }
  },


  async [FETCH_COMPANY_DETAILS] ({ commit }, id) {
    try {
      const companyDetails = await request(`${config.baseUrl}/company-detail/${id}`)
      commit(SET_SELECTED, companyDetails)
    } catch (err) {
      // TODO: HANDLE ERROR
    }
  },
  async [FETCH_EXHIBITOR_PRODUCTS] ({ commit }, id) {
    try {
      const exhibitorProducts = await request(`${config.baseAPIV2FloorplanUrl}/exhibitor-products/${id}`)
      exhibitorProducts.forEach(ep => {
          if (ep.images && ep.images[0] && ep.images[0].image){
            ep.image = ep.images[0].image}
        }
      )
      commit(SET_PRODUCTS, exhibitorProducts)
    } catch (err) {
      // TODO: HANDLE ERROR
    }
  }
}

const mutations = {
  [SET_SELECTED] (state, selected) {
    state.selected = selected
  },
  [SET_SELECTED_EXHIBITOR] (state, selected) {
    state.selectedExhibitor = selected
  },

  [SET_PRODUCTS] (state, products) {
    state.products = products
  },

  [SET_PRODUCT] (state, product) {
    state.product = product
  },

  [SHIFT_IMAGE] (state, count) {
    const index = state.products.findIndex(e => e.id === state.product.id)
    const newIndex = (index + count) - Math.floor((index + count) / state.products.length) * state.products.length
    state.product = state.products[newIndex]
  },

  [CLEAR_PRODUCT] (state) {
    state.product = null
  },

  [CLEAR_COMPANY] (state) {
    state.products = [],
      state.selectedExhibitor = {}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}