import axios from 'axios'

const parseJSON = (response) => {
  if (response.status === 204) {
    return response;
  }
  return response.data;
};
const checkStatus = (response) => {
  if (response.success) {
    return response
  }

  if (response.status >= 200 && response.status < 300) {
    return response
  }

  const error = new Error(response.statusText)
  error.response = response
  throw error
}


export default function request(url, options) {
  // this currently follows the pattern from the floorplan instead of the exhibitor catalogue
  // as we do not need to intercept routes
  const handler = axios;
  return handler(url, options)
    .then(checkStatus)
    .then(parseJSON)
    .catch((err) => {
      throw err;
    });
}