import L from 'leaflet'
import {FETCH_EXHIBITOR, SET_SELECTED} from './companies.js'

// getter names
export const BOOTHS = 'GEODATA/BOOTHS'
export const SECTIONS = 'GEODATA/SECTIONS'
export const PAVILIONS = 'GEODATA/PAVILIONS'
export const MINIMAP = 'GEODATA/MINIMAP'
export const MAP = 'GEODATA/MAP'
export const ZOOM = 'GEODATA/ZOOM'
export const ZOOMLEVEL = 'GEODATA/ZOOMLEVEL'
export const ZOOM_META = 'GEODATA/ZOOM_META'
export const CENTER = 'GEODATA/CENTER'

// action names
export const ZOOM_TO_BOOTH = 'GEODATA/ZOOM_TO_BOOTHS'

// mutation names
export const SET_BOOTHS = 'GEODATA/SET_BOOTHS'
export const SET_SECTIONS = 'GEODATA/SET_SECTIONS'
export const SET_PAVILIONS = 'GEODATA/SET_PAVILIONS'
export const SET_MINIMAP = 'GEODATA/SET_MINIMAP'
export const SET_MAP = 'GEODATA/SET_MAP'
export const SET_ZOOM = 'GEODATA/SET_ZOOM'
export const SET_ZOOM_META = 'GEODATA/SET_ZOOM_META'
export const SET_CENTER = 'GEODATA/SET_CENTER'

const state = {
  map: {},
  booths: {},
  sections: {},
  pavilions: {},
  minimap: {},
  zoom: 0,
  zoomMeta: {},
  center: {}
}

const getters = {
  [BOOTHS]: state => state.booths,
  [SECTIONS]: state => state.sections,
  [MAP]: state => state.map,
  [PAVILIONS]: state => state.pavilions,
  [MINIMAP]: state => state.minimap,
  [ZOOM]: state => state.zoom,
  [ZOOMLEVEL]: state => {
    const zoom = state.zoom
    const zoomMeta = state.zoomMeta

    if (zoom < zoomMeta.booth_section_start) return 'pavilions'
    if (zoom >= zoomMeta.booth_section_start && zoom < zoomMeta.booth_detail_start) return 'sections'
    if (zoom >= zoomMeta.booth_detail_start) return 'booths'
  },
  [ZOOM_META]: state => state.zoomMeta,
  [CENTER]: state => state.center
}

const actions = {
  [ZOOM_TO_BOOTH] ({ dispatch, commit, state }, boothLabel) {
    const map = state.map
    const zoomMeta = state.zoomMeta
    const booth = state.booths.features.find(booth => booth.properties.booth === boothLabel)

    if (booth !== undefined) {
      const center = L.latLngBounds(booth.geometry.coordinates).getCenter()
      const newCenter = { lat: center.lng, lng: center.lat }
      map.setView(newCenter, zoomMeta.booth_zoom_level)

      const exhibitors = booth.properties.exhibitors || []

      const exhibitor = exhibitors.find(e => !e.is_co_exhibitor && !e.is_pre_exhibitor)
      if (exhibitor) {
        dispatch(FETCH_EXHIBITOR, {exhibitorId: exhibitor.exhibitor_id}, {root: true})
      }

      // Set pre exhibitor as selected company (e.g. for initial render pre exhibitor/company detail initialization)
      const preExhibitor = exhibitors.find(e => e.is_pre_exhibitor)
      if (preExhibitor) {
        commit(SET_SELECTED, { name: preExhibitor.name })
      }
    }
  }
}

const mutations = {
  [SET_BOOTHS] (state, booths) {
    state.booths = booths
  },

  [SET_SECTIONS] (state, sections) {
    state.sections = sections
  },

  [SET_MAP] (state, map) {
    state.map = map
  },

  [SET_PAVILIONS] (state, pavilions) {
    state.pavilions = pavilions
  },

  [SET_MINIMAP] (state, minimap) {
    state.minimap = minimap
  },

  [SET_ZOOM] (state, zoom) {
    state.zoom = zoom
  },

  [SET_ZOOM_META] (state, zoomMeta) {
    state.zoomMeta = zoomMeta
  },

  [SET_CENTER] (state, center) {
    state.center = center
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
