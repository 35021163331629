<template>
  <div class="wrapper" :style="`background-image: url(${tradexPlusBackground});`">
    <a href="https://www.tradex-services.com/virtual-fair-list/" target="_blank">
      <img :src="tradexPlusLogo" class="tradex-plus-logo" />
    </a>

    <div class="logo-grid">
      <a v-for="company in companies" :key="company.id" :href="`#${company.id}`" class="logo-wrapper">
        <ImageItem v-if="company.logo" :source="company.logo" :alt="`${company.name} logo`" />
        <div v-if="!company.logo">{{ company.name }}</div>
      </a>
    </div>
  </div>
</template>

<script>
import ImageItem from './ImageItem.vue'

export default {
  name: 'TradexPlusMembers',
  components: {
    ImageItem,
  },
  props: {
    tradexPlusLogo: {
      type: String,
      required: true,
    },
    tradexPlusBackground: {
      type: String,
      required: true,
    },
    companies: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style scoped>
.wrapper {
  padding: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  margin-bottom: 10px;
}

.wrapper .tradex-plus-logo {
  display: block;
  max-width: 240px;
}

.wrapper .logo-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-top: 10px;
}

@media (max-width: 991px) {
  .wrapper .logo-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 755px) {
  .wrapper .logo-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 540px) {
  .wrapper .logo-grid {
    grid-template-columns: 1fr;
  }
}

.wrapper .logo-grid .logo-wrapper {
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  width: 100%;
  height: 100px;
  padding: 10px;
}

.wrapper .logo-grid .logo-wrapper img {
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
</style>
