<template>
  <div v-if="visible" class="cookie-warning">
    <div>Ich stimme zu, dass diese Seite Cookies für Analysen und personalisierten Inhalt verwendet.</div>
    <div><a :href="privacyPolicyUrl">Erfahren Sie mehr</a></div>
    <div @click="handleDismissClick()" class="cookie-warning-close">Schließen</div>
  </div>
</template>

<script>
export default {
  name: 'cookie-warning',
  props: {
    privacyPolicyUrl: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      visible: false,
    }
  },
  methods: {
    handleDismissClick: function () {
      localStorage.setItem('closedCookieWarning', true)
      this.visible = false
    }
  },
  created () {
    if (localStorage.getItem('closedCookieWarning')) {
      this.visible = false
    } else {
      this.visible = true
    }
  }
}
</script>

<style scoped>
.cookie-warning {
	position: fixed;
	right: 20px;
	bottom: 20px;
	max-width: 400px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  background-color: #1E2733;
	color: white;
	padding: 20px;
  box-sizing: border-box;
  border-radius: 3px;
	z-index: 2000;
}

.cookie-warning .cookie-warning-close {
  color: #B3E5FC;
  cursor: pointer;
}
</style>
