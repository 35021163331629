import Vue from 'vue'
import Vuex from 'vuex'

import companies from './modules/companies.js'
import fairs from './modules/fairs.js'
import geodata from './modules/geodata.js'
import boothSetup from "./modules/boothSetup.js";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    companies,
    fairs,
    geodata,
    boothSetup
  }
})
