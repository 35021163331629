<template>
  <div>
    <div v-if="loading" class="text-center">
      <div class="loading-indicator"></div>
    </div>

    <div v-if="!loading">
      <div class="category-view-toggle">
        <div @click="showSelectedCategories" :class="`${!allCategoriesVisible ? 'active' : 'inactive'}`">
          Selected
        </div>
        <div @click="showAllCategories" :class="`${allCategoriesVisible ? 'active' : 'inactive'}`">
          All
        </div>
      </div>
      <category-tree-showguide-item v-for="category in productCategoriesToShow" :key="category.id" :category="category" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'category-tree-showguide',
  props: {
    token: { type: String, required: true },
    productCategoriesUrl: { type: String, required: true },
    selectedProductCategoriesUrl: { type: String, required: true },
  },
  data () {
    return {
      loading: false,
      productCategories: [],
      selectedProductCategoryIds: [],
      allCategoriesVisible: false,
    }
  },
  computed: {
    productCategoriesToShow: function () {
      if (this.allCategoriesVisible === true) {
        return this.productCategories
      }
      return this.getSelectedCategories(this.productCategories.slice())
    },
  },
  methods: {
    async fetchProductCategories () {
      try {
        const res = await fetch(this.productCategoriesUrl, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': this.token
          },
          credentials: 'include',
        })
        const { product_categories: productCategories } = await res.json()

        if (productCategories) {
          this.productCategories = productCategories
        }
      } catch (err) {
        console.error('Unable to fetch selected product categories', err)
      }
    },
    async fetchSelectedProductCategories () {
      try {
        const res = await fetch(this.selectedProductCategoriesUrl, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': this.token
          },
          credentials: 'include',
        })
        const { selected_product_categories: selectedProductCategoryIds } = await res.json()

        if (selectedProductCategoryIds) {
          this.selectedProductCategoryIds = selectedProductCategoryIds
        }
      } catch (err) {
        console.error('Unable to fetch product categories', err)
      }
    },
    showSelectedCategories () {
      this.allCategoriesVisible = false
    },
    showAllCategories () {
      this.allCategoriesVisible = true
    },
    getSelectedCategories (arr = []) {
      return arr
        .filter((item) => this.selectedProductCategoryIds.includes(item.id))
        .map(item => ({
          ...item,
          children: this.getSelectedCategories(item.children),
        }))
    },
  },
  async created () {
    this.loading = true
    await this.fetchProductCategories()
    await this.fetchSelectedProductCategories()
    this.loading = false
  }
}
</script>

<style scoped>
.category-view-toggle {
  display: grid;
  grid-template-columns: 1fr 1fr;
  cursor: pointer;
  border-radius: 3px;
  overflow: hidden;
  margin: 9px 0 9px 0;
}

/* Columns */
.category-view-toggle div {
  width: 100%;
  padding: 5px 10px;
  text-align: center;
  font-weight: 500;
  background-color: #EFEFEF;
}

.category-view-toggle .inactive:hover {
  background-color: #DEDEDE;
}

.category-view-toggle .active {
  background-color: #008bc6;
  color: white;
}
</style>
