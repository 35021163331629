const getAlphabetizedGroups = (arr = []) => {
  const numbersGroup = {
    group: '#',
    children: arr.filter((item) => !isNaN(parseInt(item.name[0])) && typeof parseInt(item.name[0]) === 'number')
  }
  const alphabetGroups = arr.filter((item) => isNaN(parseInt(item.name[0])) || typeof parseInt(item.name[0]) !== 'number')

  const groupedAlphabet = Object.values(alphabetGroups.reduce((r, e) => {
    let group = e.name[0].toUpperCase()
    if(!r[group]) r[group] = { group, children: [e] }
    else r[group].children.push(e);
    return r;
  }, {}))

  return [groupedAlphabet, numbersGroup.children.length > 0 ? numbersGroup : null]
}

const getCategorizedGroups = (arr = []) => {
  const categories = [...new Set(arr.flatMap((item) => item.tags))].sort((c1, c2) => c1.localeCompare(c2))

  return categories.map((c) => ({
    group: c,
    children: arr.filter((item) => item.tags.includes(c))
  }))
}

export {
  getAlphabetizedGroups,
  getCategorizedGroups,
}
