<template>
  <div>
    <div class="tabs">
      <ul>
        <li v-for="tab in tabs" :class="{ 'is-active': tab.isActive }" :key="tab.name">
          <img :src="tab.icon" @click="selectTab(tab)" />
        </li>
      </ul>
    </div>
    <div class="tab-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tabs',
  data () {
    return {
      tabs: [],
    }
  },
  methods: {
    selectTab (selectedTab) {
      this.tabs.forEach((tab) => {
        if (tab.name === selectedTab.name) {
          tab.isActive = true

          // Handle additional request on tab click
          if (tab.hasAdditionalRequest === true && tab.additionalRequestUrl !== '') {
            let requestOptions = {
              method: 'GET',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
              }
            }

            if (tab.additionalRequestToken) {
              requestOptions.headers['X-CSRFToken'] = tab.additionalRequestToken
            }

            if (tab.additionalRequestMethod !== '') {
              requestOptions.method = tab.additionalRequestMethod
            }

            if (Object.keys(tab.additionalRequestBody).length > 0) {
              requestOptions.body = JSON.stringify(tab.additionalRequestBody)
            }

            fetch(tab.additionalRequestUrl, requestOptions)
              .then((response) => response.json())
              .then((data) => {
                console.log('Success:', data)
              })
              .catch((error) => {
                console.error('Error:', error)
              })
          }
        } else {
          tab.isActive = false
        }
      })
    }
  },
  created () {
    this.tabs = this.$children
  }
}
</script>
