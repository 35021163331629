<template>
  <transition :name="position">
    <div class="track-container" :class="[position]">
      <div class="track" :style="{ backgroundColor: color }">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    color: { type: String, default: 'white'},
    position: { type: String, required: true},
  }
}
</script>


<style scoped>
.track {
  height: inherit;
  width: inherit;

  overflow-y: auto;
  overflow-x: hidden;

  -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.track-container {
  position: absolute;
  width: 460px;
  z-index: 5000;
  height: 100%;
}

@media screen and (max-width: 600px) {
  .track-container {
    position: absolute;
    width: 300px;
    z-index: 5000;
  }
}
@media screen and (max-width: 400px) {
  .track-container{
    width: 200px;
  }
}

.right {
  right: 0;
}

.left {
  left: 0;
}

.left-enter-active, .left-leave-active, .right-enter-active, .right-leave-active {
  transition: transform .5s;
}

.left-enter, .left-leave-to {
  transform: translateX(-460px);
}

.left-leave, .left-enter-to {
  transform: translateX(0px);
}

.right-enter, .right-leave-to {
  transform: translateX(460px);
}

.right-leave, .right-enter-to {
  transform: translateX(0px);
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
</style>
