<template>
  <div>
    <div v-for="g in companyGroups" :key="g.group">
      <h2 class="content-heading">
        <!-- This construct is needed to add an offset to the anchors (see css below) -->
        <span :id="g.group" />{{ g.group }}
      </h2>
      <div class="card-grid">
        <div
          v-for="company in g.children"
          :key="company.id"
          @click="$emit('selectCard', { companyId: company.id })"
          :id="company.id"
          class="card"
          :style="company.isTradexPlusMember ? 'border: 4px solid #008cc6;' : ''">
          <div class="image-wrapper">
            <ImageItem v-if="company.logo" :source="company.logo" :alt="`${company.name} logo`" />
          </div>
          <div class="company-name" :style="company.isTradexPlusMember ? 'background-color: #008cc6;' : ''">
            {{ company.name }}
          </div>
          <div v-if="company.isTradexPlusMember" class="plus">
            <div class="plus-text">&#65291;</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageItem from './ImageItem.vue'

export default {
  name: 'Content',
  components: {
    ImageItem,
  },
  props: {
    companyGroups: {
      type: Array,
      default: () => [],
    },
  }
}
</script>

<style scoped>
h2.content-heading {
  margin: 0;
  padding: 20px 10px 10px 10px;
  font-size: 1.8rem;
  color: #008cc6;
  position: relative;
}

/* This construct is needed to add an offset to the anchors (see markup above) */
h2.content-heading span {
  position: absolute;
  top: -50px;
}

.card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

@media (max-width: 540px) {
  .card-grid {
    grid-template-columns: 1fr;
  }
}

.card-grid .card {
  transition: box-shadow .2s ease;
  position: relative;
  height: 200px;
  background-color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  overflow: hidden;
}

.card-grid .card:hover {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.card-grid .card .image-wrapper {
  max-width: 100%;
  height: calc(100% - 34px);
  padding: 20px;
}

.card-grid .card .company-name {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(0, 0, 0, .7);
  color: white;
  font-weight: 500;
  text-align: center;
  padding: 5px 10px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-grid .card .plus {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 70px solid #008cc6;
  border-left: 70px solid transparent;
}

.card-grid .card .plus .plus-text {
  position: absolute;
  top: -70px;
  right: 5px;
  color: white;
  font-weight: 800;
  font-size: 1.8rem;
}
</style>
