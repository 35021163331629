export const parseDrfErrors = function(data, subsituteDict) {
  if (subsituteDict === undefined) {
    subsituteDict = {}
  }
  function _camelCaseToVerbose(text) {
    return text.replace(/(?=[A-Z])/g, ' ');
  }

  function _underscoredToVerbose(text) {
    return text.replace(/[\d_]/g, ' ');
  }

  function _capitalize(text) {
    text = text.toLowerCase();
    text = text.charAt(0).toUpperCase() + text.slice(1);
    return text;
  }
  function isString(value) {
    return typeof (value) === "string"
  }
  function isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object
  }
  function substituteKey(key) {
    if (key in subsituteDict){
      return subsituteDict[key]
    }
    return key
  }

  function _parseErrorItem(item, listPos) {
    let output = [];
    Object.entries(item).forEach(function([key, value]) {
      let plainValue;
      let listValue;
      if (isString()) {
        plainValue = value;
      } else if (Array.isArray(value)) {
        if (isString(value[0])) {
          plainValue = value.join(' ');
        } else {
          listValue = _parseErrorList(value);
        }
      }
      let content;
      if (plainValue) {
        content = plainValue;
      } else if (listValue) {
        content = listValue;
      }
      if (key !== substituteKey(key)){
        key = substituteKey(key)
      }else{
        if (content) {
          // Auto capitalize non substituted keys
          if (key.search(/[A-Z]/) != -1)
            key =_camelCaseToVerbose(key);
          if (key.search(/[\d_]/) != -1)
            key =_underscoredToVerbose(key);
          key = _capitalize(key);
        }
      }
      if (listValue){
        listValue.forEach((errorMessage) => {
          output.push(`Error at ${key}: ${errorMessage}`)
        })
      }else {
        output.push(`Error at ${key}: ${content}`)
      }
    });
    return output;
  }

  function _parseErrorList(items) {
    let output = [];
    items.forEach(function(item, i) {
      if (!isEmptyObject(item)) {
        output.push(_parseErrorItem(item, i + 1));
      }
    });
    //output = output.join('');
    return output;
  }
  if (Array.isArray(data)) {
    return _parseErrorList(data);
  }
  else {
    return _parseErrorItem(data);
  }
};