<template>
  <div>
    <div v-on:click="handleButtonClick">
      <slot name="confirmation-modal-trigger">
        <div>Confirmation Modal Trigger</div>
      </slot>
    </div>
    <div v-bind:style="overlayStyle" v-if="visible">
      <div v-bind:style="contentWrapperStyle">
        <form @submit="handleFormSubmit">
          <h1 style="font-size: 1.7rem; margin-bottom: 20px;">
            Please provide your email to download files
          </h1>

          <input type="email" name="email" maxlength="254" required placeholder="E-Mail" v-bind:style="inputStyle" class="form-control" />
          <input type="text" name="companyName" maxlength="256" required placeholder="Company" v-bind:style="inputStyle" class="form-control" />

          <div>
            <label for="info-material">
              <input type="checkbox" id="info-material" name="sendInfoMaterial" />
              Tradex Services is allowed to send me informational material.
            </label>
          </div>
          <div>
            <label for="terms-and-conditions">
              <input type="checkbox" id="terms-and-conditions" name="terms" required />
              We accept the <a v-bind:href="tocUrl" target="_blank">Terms and Conditions of Participation</a>
            </label>
          </div>
          <div>
            <label for="data-protection">
              <input type="checkbox" id="data-protection" name="dsgvo" required />
              We accept and have read the <a v-bind:href="dsgvoUrl" target="_blank">Data Protection Declaration</a>
            </label>
          </div>

          <div style="text-align: right; margin-top: 10px;">
            <button class="btn btn-primary">Send</button>
            <span v-on:click="handleButtonClick" v-bind:style="cancelLinkStyle">Cancel</span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'download-confirmation-modal',
  props: {
    token: {
      type: String,
      required: true,
    },
    isUserLoggedIn: {
      type: Boolean,
      required: true,
    },
    eventId: {
      type: String,
      required: true,
    },
    tocUrl: {
      type: String,
      required: true,
    },
    dsgvoUrl: {
      type: String,
      required: true,
    },
    downloadUrl: {
      type: String,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    }
  },
  data: function () {
    return {
      visible: false,
      overlayStyle: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, .5)',
        zIndex: '1030',
      },
      contentWrapperStyle: {
        maxWidth: '600px',
        margin: '80px auto 0 auto',
        padding: '20px',
        boxSizing: 'border-box',
        backgroundColor: 'white',
        borderRadius: '3px',
        boxShadow: '1px 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
      },
      cancelLinkStyle: {
        color: '#008bc6',
        fontSize: '1rem',
        padding: '0 5px',
        cursor: 'pointer',
      },
      inputStyle: {
        display: 'block',
        marginBottom: '10px',
        width: '100%',
      }
    }
  },
  methods: {
    handleButtonClick: function () {
      // Authenticated user can download immediately
      if (this.isUserLoggedIn) {
        this.triggerDownload()
      } else {
        // User that have already been registered as a lead can download too
        if (localStorage.getItem('leadAlreadySaved') === 'true') {
          this.triggerDownload()
        } else {
          this.visible = !this.visible
        }
      }
    },
    handleFormSubmit: async function (e) {
      e.preventDefault()

      const { email, companyName, sendInfoMaterial, terms, dsgvo } = e.target

      let requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': this.token,
        },
        body: JSON.stringify({
          e_mail: email.value,
          company_name: companyName.value,
          event: this.eventId,
          send_material: sendInfoMaterial.checked,
          terms: terms.checked,
          file: this.downloadUrl,
          source: this.fileName,
          dsgvo: dsgvo.checked,
        })
      }

      try {
        await fetch('/lead/', requestOptions)
        localStorage.setItem('leadAlreadySaved', true)
      } catch (err) {
        console.error('Error:', error)
      }

      this.triggerDownload()
      this.visible = false
    },
    triggerDownload: function () {
      const a = document.createElement('a')
      a.style.display = 'none'
      document.body.appendChild(a)
      a.href = this.downloadUrl
      a.setAttribute('download', this.fileName)
      a.click()
      document.body.removeChild(a)
    }
  }
}
</script>
