require('whatwg-fetch').default
require('./vendors/date-picker-polyfill')
require('./app.scss')

import store from './store'
import { background } from './components/virtualfair/utils/style'
import { link } from './components/virtualfair/utils/window'
import { parseDrfErrors} from "./components/utils/parseDrfErrors";

import Vue from 'vue'
import VueThreeSixty from 'vue-360'
Vue.prototype.$link = link;
Vue.prototype.$background = background;
Vue.prototype.$parseDjangoRestErorrs = parseDrfErrors;
Vue.use(VueThreeSixty)
// vue-awesome-swiper doesn't work with IE11... Therefore it is not initialized.
if (!document.documentMode) {
  const VueAwesomeSwiper = require('vue-awesome-swiper').default
  Vue.use(VueAwesomeSwiper)
}

const Dropdown = require('./components/Dropdown.vue').default
const Alert = require('./components/Alert.vue').default
const Modal = require('./components/Modal.vue').default
const Tab = require('./components/Tab.vue').default
const Tabs = require('./components/Tabs.vue').default
const Gallery = require('./components/Gallery.vue').default
const CategoryTree = require('./components/CategoryTree.vue').default
const DownloadConfirmationModal = require('./components/DownloadConfirmationModal.vue').default
const CalendarExport = require('./components/CalendarExport.vue').default
const EventRegistrationForm = require('./components/EventRegistrationForm.vue').default
const FormFieldWithPreview = require('./components/FormFieldWithPreview.vue').default
const CategoryTreeShowguide = require('./components/CategoryTreeShowguide.vue').default
const CategoryTreeShowguideItem = require('./components/CategoryTreeShowguideItem.vue').default
const Catalog = require('./components/catalog/components/Catalog.vue').default
const AutoCompleteInput = require('./components/AutoCompleteInput.vue').default
const InviteExhibitorForm = require('./components/InviteExhibitorForm.vue').default
const RemindExhibitorForm = require('./components/RemindExhibitorForm.vue').default
const GlobalAlerts = require('./components/GlobalAlerts.vue').default
const CookieWarning = require('./components/CookieWarning.vue').default
// const VirtualBoothGeneric = require('./components/VirtualBoothGeneric.vue').default // apparently this is needed for the async import in the case of VB-generic

import RotationImage from './components/RotationImage.vue';
import { VModal, VTrack } from './components/virtualfair/components'

Vue.component('vf-appointment-button', () => import(/* webpackChunkName: "vf-appointment-button" */ './components/virtualfair/components/AppointmentButton.vue'));
Vue.component('vf-call-me-back-button', () => import(/* webpackChunkName: "vf-call-me-back-button" */ './components/virtualfair/components/CallMeBackButton.vue'));
Vue.component('vf-loader', () => import(/* webpackChunkName: "vf-loader" */ './components/virtualfair/components/Loader.vue'));
Vue.component('vf-modal', () => import(/* webpackChunkName: "vf-modal" */ './components/virtualfair/components/Modal.vue'));
Vue.component('vf-track', () => import(/* webpackChunkName: "vf-track" */ './components/virtualfair/components/Track.vue'));
Vue.component('vf-company-detail', () => import(/* webpackChunkName: "vf-company-detail" */ './components/virtualfair/pages/Map/CompanyDetail.vue'));
Vue.component('vf-fair-list', () => import(/* webpackChunkName: "vf-fair-list" */ './components/virtualfair/pages/Map/FairList.vue'));
Vue.component('vf-mini-map', () => import(/* webpackChunkName: "vf-mini-map" */ './components/virtualfair/pages/Map/Minimap.vue'));
Vue.component('vf-navbar', () => import(/* webpackChunkName: "vf-navbar" */ './components/virtualfair/pages/Map/Navbar.vue'));
Vue.component('vf-overlay-button', () => import(/* webpackChunkName: "vf-overlay-button" */ './components/virtualfair/pages/Map/OverlayButton.vue'));
Vue.component('vf-product-card', () => import(/* webpackChunkName: "vf-product-card" */ './components/virtualfair/pages/Map/ProductCard.vue'));
Vue.component('vf-product-details', () => import(/* webpackChunkName: "vf-product-details" */ './components/virtualfair/pages/Map/ProductDetails.vue'));
Vue.component('vf-floor-plan-map', () => import(/* webpackChunkName: "vf-floor-plan-map" */ './components/virtualfair/pages/Map/FloorPlanMap.vue'));
Vue.component('vf-floor-plan', () => import(/* webpackChunkName: "vf-floor-plan" */ './components/virtualfair/pages/Map/FloorPlan.vue'));
Vue.component('v-modal', VModal)
Vue.component('v-track', VTrack)


Vue.component('booth-setup', () => import(/* booth-setup */ './components/pixi/BoothSetup.vue'));
Vue.component('setupCanvas', () => import(/* setup-canvas */ './components/pixi/SetupCanvas.vue'));

// Register Vue components
Vue.component('dropdown', Dropdown)
Vue.component('alert', Alert)
Vue.component('modal', Modal)
Vue.component('tab', Tab)
Vue.component('tabs', Tabs)
Vue.component('gallery', Gallery)
Vue.component('category-tree', CategoryTree)
Vue.component('download-confirmation-modal', DownloadConfirmationModal)
Vue.component('calendar-export', CalendarExport)
Vue.component('event-registration-form', EventRegistrationForm)
Vue.component('form-field-with-preview', FormFieldWithPreview)
Vue.component('category-tree-showguide', CategoryTreeShowguide)
Vue.component('category-tree-showguide-item', CategoryTreeShowguideItem)
Vue.component('catalog', Catalog)
Vue.component('auto-complete-input', AutoCompleteInput)
Vue.component('invite-exhibitor-form', InviteExhibitorForm)
Vue.component('remind-exhibitor-form', RemindExhibitorForm)
Vue.component('global-alerts', GlobalAlerts)
Vue.component('cookie-warning', CookieWarning)
//Vue.component('rotation-image', RotationImage)

// Dynamic imports for code splitting (don't forget to add the webpack bundle tags to the corresponding pages)
Vue.component('virtual-booth-generic', () => import(/* webpackChunkName: "virtual-booth-generic" */ './components/VirtualBoothGeneric.vue'))
Vue.component('world-map', () => import(/* webpackChunkName: "world-map" */ './components/WorldMap.vue'));
Vue.component('virtual-booth', () => import(/* webpackChunkName: "virtual-booth" */ './components/VirtualBooth.vue'));
Vue.component('additional-order', () => import(/* webpackChunkName: "additional-order" */ './components/AdditionalOrder.vue'));
Vue.component('appointment-calendar', () => import(/* webpackChunkName: "appointment-calendar" */ './components/AppointmentCalendar.vue'));
Vue.component('exhibitor-actions-zip-button', () => import(/* webpackChunkName: "exhibitor-actions-zip-button" */ './components/ExhibitorActionsZipButton.vue'));
Vue.component('freight-form', () => import(/* webpackChunkName: "freight-form" */ './components/FreightForm.vue'))
// virtual booth create
Vue.component('virtual-booth-create-form', () => import(/* webpackChunkName: "virtual-booth-create-form" */ './components/VirtualBoothCreateForm.vue'));
// virtual booth edit forms
Vue.component('virtual-booth-edit-form', () => import(/* webpackChunkName: "virtual-booth-edit-form" */ './components/VirtualBoothEditForm.vue'));
Vue.component('virtual-booth-item-download-form', () => import(/* webpackChunkName: "virtual-booth-item-download-form" */ './components/VirtualBoothItemDownloadForm.vue'));
Vue.component('virtual-booth-texture-choice-form', () => import(/* webpackChunkName: "virtual-booth-texture-choice-form" */ './components/VirtualBoothTextureChoice.vue'));
Vue.component('virtual-booth-item-form', () => import(/* webpackChunkName: "virtual-booth-item-form" */'./components/VirtualBoothItemForm.vue'));
Vue.component('virtual-booth-product-form', () => import(/* webpackChunkName: "virtual-booth-product-form" */ './components/VirtualBoothProductForm.vue'));
Vue.component('virtual-booth-external-link-form', () => import(/* webpackChunkName: "virtual-booth-external-link-form" */ './components/VirtualBoothExternalLinkForm.vue'));
Vue.component('virtual-booth-video-form', () => import(/* webpackChunkName: "virtual-booth-video-form" */ './components/VirtualBoothVideoForm.vue'));
Vue.component('virtual-booth-rotation-image-form', () => import(/* webpackChunkName: "virtual-booth-rotation-image-form" */ './components/VirtualBoothRotationImageForm.vue'));
Vue.component('rotation-image', () => import(/* webpackChunkName: "rotation-image" */ './components/RotationImage.vue'));

Vue.component('video-chat', () => import(/* webpackChunkName: "video-chat" */ './components/VideoChat.vue'));
// Register global Vue entry point. Can be found in templates/v2/base.html.
new Vue({
  el: '#tradex-vue-app',
  store,
})
