<template>
  <figure v-lazyload>
    <img :data-url="source" :alt="alt" style="display: block;">
  </figure>
</template>

<script>
import LazyLoadDirective from '../directives/LazyLoadDirective'

export default {
  name: 'ImageItem',
  directives: {
    lazyload: LazyLoadDirective,
  },
  props: {
    source: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
    }
  }
}
</script>

<style scoped>
figure {
  margin: 0;
  padding: 0;
  height: 100%;
  display: grid;
}

img {
  display: block;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
</style>
