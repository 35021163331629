import flatpickr from 'flatpickr';
import EventBus from '../event-bus';

/**
 * This date picker is not an actual Vue component, but an extension to
 * date/time input fields in browsers which don't support those fileds.
 */

function _browserSupportsDateInput () {
  let testDateField = document.createElement('input');
  testDateField.setAttribute('type', 'date');
  return testDateField.type === 'date';
}

function _browserSupportsTimeInput () {
  let testDateField = document.createElement('input');
  testDateField.setAttribute('type', 'time');
  return testDateField.type === 'time';
}

function _mountDatePickerOnDateInputs() {
  const allInputs = document.getElementsByTagName('input');

  for(var i = 0; i < allInputs.length; i++) {
    // Mount date inputs
    if(allInputs[i].dataset['input-type'] === 'date') {
      flatpickr(allInputs[i], {
        static: true
      });
    }
  }
}

function _mountTimePickerOnTimeInputs() {
  const allInputs = document.getElementsByTagName('input');

  for(var i = 0; i < allInputs.length; i++) {
    // Mount time inputs
    if(allInputs[i].dataset['input-type'] === 'time') {
      flatpickr(allInputs[i], {
        static: true,
        enableTime: true,
        noCalendar: true,
        time_24hr: true
      });
    }
  }
}

document.addEventListener('DOMContentLoaded', function(){
  if (!_browserSupportsDateInput()) {
    // Mount on page elements
    _mountDatePickerOnDateInputs()

    EventBus.$on('dropdown', ({ expanded }) => {
      if (expanded) {
        // Mount on dropdown elements
        _mountDatePickerOnDateInputs()
      }
    })
  }

  if (!_browserSupportsTimeInput()) {
    // Mount on page elements
    _mountTimePickerOnTimeInputs()

    EventBus.$on('dropdown', ({ expanded }) => {
      if (expanded) {
        // Mount on dropdown elements
        _mountTimePickerOnTimeInputs()
      }
    })
  }
}, false);
