<template>
  <div v-if="visible" v-bind:style="mergedWrapperStyle">
    <div>
      <slot></slot>
    </div>
    <div v-if="dismissable" v-bind:style="dismissStyle" v-on:click="handleDismissClick">
      &#10005;
    </div>
  </div>
</template>

<script>
export default {
  name: 'alert',
  props: {
    dismissable: {
      type: Boolean,
      default: true,
    },
    type: String,
    wrapperStyle: Object,
  },
  data: function () {
    return {
      visible: true,
      baseWrapperStyle: {
        position: 'relative',
        padding: '10px 32px 10px 16px',
        boxSizing: 'border-box',
        borderRadius: '3px',
      },
      dismissStyle: {
        position: 'absolute',
        top: '6px',
        right: '10px',
        bottom: 0,
        margin: 'auto',
        cursor: 'pointer',
        fontSize: '1.4rem',
      }
    }
  },
  computed: {
    mergedWrapperStyle: function () {
      let backgroundColor = 'rgba(0, 0, 0, .1)'
      let color = 'inherit'

      // Types taken from message levels/tags
      // Colors taken from bootstrap alerts
      if (this.type === 'alert-info') {
        backgroundColor = '#cce5ff'
        color = '#004085'
      }
      if (this.type === 'alert-success') {
        backgroundColor = '#d4edda'
        color = '#155724'
      }
      if (this.type === 'alert-warning') {
        backgroundColor = '#fff3cd'
        color = '#856404'
      }
      if (this.type === 'alert-danger') {
        backgroundColor = '#f8d7da'
        color = '#721c24'
      }

      return {
        ...this.baseWrapperStyle,
        backgroundColor,
        color,
        ...this.wrapperStyle,
      }
    }
  },
  methods: {
    handleDismissClick: function () {
      this.visible = false
    }
  }
}
</script>
