<template>
  <div>
    <button @click.prevent="handleClick" :disabled="disabled" class="btn btn-primary">
      {{ buttonText }}
    </button>
    <div v-if="loading" class="remind-exhibitor-notification">
      Reminding...
    </div>
    <div v-if="!loading && notificationText" class="remind-exhibitor-notification">
      {{ notificationText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'remind-exhibitor-form',
  props: {
    token: {
      type: String,
      required: true,
    },
    requestUrl: {
      type: String,
      required: true,
    },
    slugs: {
      type: Array,
      required: true
    },
    buttonText: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      loading: false,
      disabled: false,
      notificationText: '',
    }
  },
  methods: {
    handleClick: async function () {
      try {
        this.loading = true;

        const res = await fetch(this.requestUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': this.token
          },
          body: JSON.stringify({slugs: this.slugs}),
          credentials: 'include',
        });

        if (res.status === 200) {
          this.disabled = true
          this.notificationText = 'Reminding succeeded.'
        } else if (res.status === 400) {
          this.notificationText = (await res.json()).join()
        } else {
          this.notificationText = 'Reminding failed.'
        }
      } catch (err) {
        console.error('Unable to remind exhibitor', err)
        this.notificationText = 'Reminding failed.'
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.remind-exhibitor-notification {
  display: inline-block;
  vertical-align: middle;
  font-weight: 500;
  margin-left: 4px;
  opacity: .7;
}
</style>
