<template>
  <div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 4px;">
    <div>
      <button v-on:click="handleAllButtonClick" class="btn btn-outline-primary btn-block btn-sm">
        All (.ics file)
      </button>
    </div>
    <div>
      <a
        v-bind:href="googleCalendarUrl"
        target="_blank"
        class="btn btn-outline-primary btn-block btn-sm">
        Google
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'calendar-export',
  props: {
    eventName: {
      type: String,
      required: true,
    },
    eventLocation: {
      type: String,
      required: true,
    },
    eventStartDate: {
      type: String,
      required: true,
    },
    eventEndDate: {
      type: String,
      required: true,
    },
    googleCalendarUrl: {
      type: String,
      required: true,
    }
  },
  methods: {
    handleAllButtonClick: function () {
      this.createIcsDownload()
    },
    createIcsDownload: function () {
      /*
       * Used the ical-create-download-javascript code as the
       * provided librariesand npm packages were not useable.
       *
       * https://gist.github.com/dudewheresmycode/ff1d364c1c6d787fe7ea
       */

      const fileName = 'calendar.ics'

      this._isofix = function (d) {
        var offset = ("0" + ((new Date()).getTimezoneOffset() / 60)).slice(-2)

        if (typeof d == 'string') {
          return d.replace(/\-/g, '') + 'T' + offset + '0000Z'
        } else {
          return d.getFullYear() + this._zp(d.getMonth() + 1) + this._zp(d.getDate()) + 'T' + this._zp(d.getHours()) + "0000Z"
        }
      }

      this._zp = function (s) { return ("0" + s).slice(-2) }
      this._save = function (fileURL) {
        if (!window.ActiveXObject) {
          var save = document.createElement('a')
          save.href = fileURL
          save.target = '_blank'
          save.download = fileName || 'unknown'

          var evt = new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': false,
          })
          save.dispatchEvent(evt)

          (window.URL || window.webkitURL).revokeObjectURL(save.href)
        }

        // for IE < 11
        else if (!!window.ActiveXObject && document.execCommand) {
          var _window = window.open(fileURL, '_blank')
          _window.document.close()
          _window.document.execCommand('SaveAs', true, fileName || fileURL)
          _window.close()
        }
      }

      var ics_lines = [
        "BEGIN:VCALENDAR",
        "VERSION:2.0",
        "BEGIN:VEVENT",
        "CLASS:PUBLIC",
        "DESCRIPTION:" + this.eventName,
        "DTSTART:" + this._isofix(this.eventStartDate),
        "DTEND:" + this._isofix(this.eventEndDate),
        "LOCATION:" + this.eventLocation,
        "SUMMARY:" + this.eventName,
        "TRANSP:TRANSPARENT",
        "END:VEVENT",
        "END:VCALENDAR",
      ]

      var downloadUrl = 'data:text/calendar;base64,' + btoa(ics_lines.join('\r\n'))

      try {
        this._save(downloadUrl)
      } catch (err) {
        console.error('Error', err)
      }
    }
  }
}
</script>
