<template>
  <div v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'tab',
  props: {
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasAdditionalRequest: {
      type: Boolean,
      required: false,
      default: false,
    },
    additionalRequestToken: {
      type: String,
      required: false,
      default: '',
    },
    additionalRequestMethod: {
      type: String,
      required: false,
      default: '',
    },
    additionalRequestUrl: {
      type: String,
      required: false,
      default: '',
    },
    additionalRequestBody: {
      type: Object,
      required: false,
      default: () => ({}),
    }
  },
  data () {
    return {
      isActive: false,
    }
  },
  mounted () {
    this.isActive = this.selected
  }
}
</script>
