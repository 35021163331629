<template>
  <div v-if="visibleMessages.length > 0" class="global-alert-wrapper">
    <div v-for="(message) in visibleMessages" :key="message.id" :class="`alert ${message.tags}`">
      {{ unescape(message.message) }}
      <div class="close" @click="dismissMessage(message.id)">
        &#10005;
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'global-alerts',
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      visibleMessages: [],
    }
  },
  methods: {
    dismissMessage: function (id) {
      if (id) {
        this.visibleMessages = this.visibleMessages.filter((message) => message.id !== id)
      }
    },
    unescape: function (msg) {
      if (msg) {
        // We need to unescape the passed string as the "force_escape" is applied beforehand
        // https://stackoverflow.com/questions/1912501/unescape-html-entities-in-javascript/34064434#34064434
        const doc = new DOMParser().parseFromString(msg, 'text/html')
        return doc.documentElement.textContent
      }
    },
  },
  created () {
    if (this.messages && this.messages.length > 0) {
      this.visibleMessages = this.messages.map((message, i) => ({
        id: `message-${message.message}-${i}`,
        ...message,
      }))
    }
  }
}
</script>

<style scoped>
.global-alert-wrapper {
	position: fixed;
	right: 20px;
	bottom: 20px;
	padding: 10px;
	border-radius: 3px;
	z-index: 1030;
	background-color: white;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  max-width: 100%;
  margin-left: 20px;
}

.global-alert-wrapper .alert {
  padding: 7px 32px 7px 12px;
  margin-bottom: 5px;
}

.global-alert-wrapper .alert:last-child {
  margin-bottom: 0;
}

.global-alert-wrapper .alert .close {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  font-size: 1.4rem;
}
</style>
