import request from '../../request.js'
import api from "@/utils/api"; // todo change
const stringToColour = function (str) {
  /*
    Helper to get a random but deterministic color for each item
   */
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}
// getter names
export const INVENTORY_ITEMS = 'BOOTH_SETUP/INVENTORY_ITEMS'
export const SELECTED_CONTAINER = 'BOOTH_SETUP/SELECTED_CONTAINER'
export const CANVAS_CONTAINERS = 'BOOTH_SETUP/CANVAS_CONTAINERS'
export const BOOTH_JSON = 'BOOTH_SETUP/BOOTH_JSON'
export const BOOTH_DIMENSION = 'BOOTH_SETUP/BOOTH_DIMENSION'
export const INVENTORY_ITEMS_WITH_SIZE = 'BOOTH_SETUP/INVENTORY_ITEMS_WITH_SIZE'
export const BOOTH_ID = 'BOOTH_SETUP/BOOTH_ID'

// action names
export const FETCH_ALL_INVENTORY_ITEMS = 'BOOTH_SETUP/FETCH_ALL_INVENTORY_ITEMS'
export const FETCH_ALL_SETUP_ITEMS = 'BOOTH_SETUP/FETCH_ALL_SETUP_ITEMS'
export const FETCH_SETUP_JSON = 'BOOTH_SETUP/FETCH_BOOTH_JSON'
export const PUT_BOOTH_JSON = 'BOOTH_SETUP/PUT_BOOTH_JSON'

// mutation names
export const SET_INVENTORY_ITEMS = 'BOOTH_SETUP/SET_INVENTORY_ITEMS'
export const SET_SELECTED_CONTAINER = 'BOOTH_SETUP/SET_SELECTED_CONTAINER'
export const SET_BOOTH_JSON = 'BOOTH_SETUP/SET_BOOTH_JSON'
export const SET_BOOTH_DIMENSION = 'BOOTH_SETUP/SET_BOOTH_DIMENSION'
export const SET_BOOTH_ID = 'BOOTH_SETUP/SET_BOOTH_ID'
export const REDUCE_ITEM_COUNT = 'BOOTH_SETUP/REDUCE_ITEM_COUNT'
export const SELECTED_SPRITE_ROTATE_LEFT = 'BOOTH_SETUP/SELECTED_ROTATE_LEFT'
export const SELECTED_SPRITE_ROTATE_RIGHT = 'BOOTH_SETUP/SELECTED_ROTATE_RIGHT'
export const SELECTED_SPRITE_DESTROY = 'BOOTH_SETUP/SELECTED_DESTROY'
export const ADD_TO_CANVAS_CONTAINERS = 'BOOTH_SETUP/ADD_TO_CANVAS_CONTAINERS'
export const REMOVE_FROM_CANVAS_CONTAINERS = 'BOOTH_SETUP/REMOVE_FROM_CANVAS_CONTAINERS'
export const RESET_CANVAS = 'BOOTH_SETUP/RESET_CANVAS'


const state = {
  selected: {},
  boothId: undefined,
  inventoryItems: [],
  canvasContainers: [],
  boothJson: {},
  boothDimensions: [100, 100], // default is for debugging reasons.
}

const getters = {
  [SELECTED_CONTAINER]: state => state.selected,
  [INVENTORY_ITEMS]: state => state.inventoryItems,
  [INVENTORY_ITEMS_WITH_SIZE]: state => {
    return state.inventoryItems
      .filter(item => item.count > 0 && ((item.width && item.length) || item.size || item.forceShow))
  },
  [CANVAS_CONTAINERS]: state => state.canvasContainers,
  [BOOTH_JSON]:state => state.boothJson,
  [BOOTH_DIMENSION]:state => state.boothDimensions,
  [BOOTH_ID]:state => state.boothId,
}

const actions = {
  async [FETCH_ALL_INVENTORY_ITEMS] ({ commit }, exhibitorId) {
    try {
      const items = await request(`${api.baseV2Url}/online-manual/exhibitor/${exhibitorId}/booth-products`, {withCredentials: true})
      const strToC = stringToColour
      const addOrderItems = [...items.results].map(boothProduct =>
      {
        let l = undefined;
        let w = undefined;
        if(boothProduct.product_entry.product.length){
          l = boothProduct.product_entry.product.length
        }else if(boothProduct.product_entry.product.space) {
          l = Math.round(Math.sqrt(boothProduct.product_entry.product.space * 10000))
        }
        if(boothProduct.product_entry.product.width){
          w = boothProduct.product_entry.product.width
        }else if(boothProduct.product_entry.product.space) {
          w = Math.round(Math.sqrt(boothProduct.product_entry.product.space * 10000))
        }
        return {
          length: l,
          width: w,
          forceShow: boothProduct.product_entry.product.force_show,
          size: boothProduct.product_entry.product.space,
          name: boothProduct.product_entry.product.name,
          iconUrl: boothProduct.product_entry.product.setup_icon,
          form: boothProduct.product_entry.product.form,
          color: strToC(boothProduct.product_entry.product.name),
          count: boothProduct.amount,
          payload: boothProduct,
          boothId: boothProduct.booth
        }})
        if(addOrderItems.length > 0){
          commit(SET_BOOTH_ID, parseInt(addOrderItems[0].boothId))
        }
      commit(SET_INVENTORY_ITEMS, addOrderItems)
    } catch (err) {
      // TODO: HANDLE ERROR
    }
  },
  async [FETCH_SETUP_JSON] ({commit}, exhibitorId) {
    try {
      const response = await request(`${api.baseV2Url}/online-manual/exhibitor/${exhibitorId}/booth-setup`, {withCredentials: true,})
      commit(SET_BOOTH_JSON, response.booth_setup_json)
      commit(SET_BOOTH_DIMENSION, response.booth_dimensions)
    } catch (err){
      console.log(err)
    }
  },
}

const mutations = {
  [SET_INVENTORY_ITEMS] (state, inventoryItems) {
    state.inventoryItems = inventoryItems
  },
  [SET_SELECTED_CONTAINER] (state, selected) {
    state.selected = selected
  },
  [SET_BOOTH_JSON] (state, json){
    /*
    Json includes
      items: list of existing items
      final: bool indicating if json was saved
     */
    state.boothJson = json
  },
  [SET_BOOTH_ID] (state, id){
    if (id) {
      state.boothId = id
    }
  },
  [SET_BOOTH_DIMENSION] (state, dims){
    if (dims){
      state.boothDimensions = Array.from(dims, dim => dim * 100)
    }
  },
  [REDUCE_ITEM_COUNT] (state, item){
    const match = state.inventoryItems.find(i => i.payload.id === item.payload.id);
    if (match) {
      match.count--;
    }
  },
  [SELECTED_SPRITE_ROTATE_LEFT] (state, angle=15) {
    if(state.selected){
      state.selected.angle -= angle
    }
  },
  [SELECTED_SPRITE_ROTATE_RIGHT] (state, angle=15) {
    if(state.selected){
      state.selected.angle += angle
    }
  },
  [SELECTED_SPRITE_DESTROY] (state){
    // TODO review selection with multiple items
    const match = state.inventoryItems.find(i => i.payload.id === state.selected.payload.id)
    if (match) {
      match.count++
    }
    const index = state.canvasContainers.indexOf(state.selected)
    if (index > -1) {
      state.canvasContainers.splice(index, 1)
    }
    state.selected.destroy();
    state.selected = {}
  },
  [ADD_TO_CANVAS_CONTAINERS] (state, container){
    state.canvasContainers.push(container)
    state.canvasContainers = state.canvasContainers.sort(((a, b) => a.x - b.x
    ))
  },
  [REMOVE_FROM_CANVAS_CONTAINERS](state, container){
    const index = state.canvasContainers.indexOf(container)
    if (index > -1) {
      state.canvasContainers.splice(index, 1)
    }
  },
  [RESET_CANVAS](state){
    state.selected = {}
    state.canvasContainers.forEach(
      item => {
        state.inventoryItems.find(i => i.payload.id === item.payload.id).count++;
        item.destroy();
      })
    state.canvasContainers = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
