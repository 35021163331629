<template>
  <div class="wrapper" :style="`grid-template-columns: ${fieldWidthRatio}fr ${previewWidthRatio}fr;`">
    <div><slot></slot></div>
    <div>
      <img
        v-if="previewImageUrl !== null"
        :src="previewImageUrl"
        alt="preview image" />
    </div>
  </div>
</template>

<script>
// Currently can handle <select> fields
export default {
  name: 'form-field-with-preview',
  props: {
    requestBaseUrl: String,
    fieldWidthRatio: {
      type: Number,
      default: 1
    },
    previewWidthRatio: {
      type: Number,
      default: 1
    },
    initialId: String
  },
  data: function () {
    return {
      previewImageUrl: null,
    }
  },
  mounted () {
    if (this.$slots.default && this.$slots.default[0] && this.$slots.default[0].elm) {
      const inputElement = this.getInputElement(this.$slots.default[0].elm)

      if (inputElement && inputElement.localName === 'select') {
        // Initialize preview if an initial id is passed
        if (this.initialId) {
          this.fetchPreviewImage(this.initialId)
        }

        // Change preview on change
        inputElement.addEventListener('change', (e) => {
          this.fetchPreviewImage(e.target.value)
        })
      }
    }
  },
  methods: {
    getInputElement: function (htmlElement) {
      if (htmlElement.localName === 'select') {
        return htmlElement
      }

      if (htmlElement.children) {
        let result = null

        for (let i = 0; result === null && i < htmlElement.children.length; i++) {
          result = this.getInputElement(htmlElement.children[i])
        }

        return result
      }

      return null
    },
    fetchPreviewImage: function (id) {
      if (id) {
        fetch(this.requestBaseUrl + id)
          .then((res) => res.json())
          .then((json) => {
            // Virtual booth creation response structure
            if (json.preview_image) {
              this.previewImageUrl = json.preview_image
            }

            // Virtual booth edit response structure
            if (json.texture) {
              this.previewImageUrl = json.texture
            }
          })
          .catch((err) => {
            console.error('Unable to fetch preview image', err)
          })
      } else {
        this.previewImageUrl = null
      }
    }
  }
}
</script>

<style scoped>
.wrapper {
  display: grid;
  grid-gap: 20px;
  align-items: center;
}

img {
  display: block;
  max-width: 100%;
}
</style>
