// https://css-tricks.com/lazy-loading-images-with-vue-js-directives-and-intersection-observer/#article-header-id-3
export default {
  inserted: el => {
    function loadImage() {
      const imageElement = Array.from(el.children).find(
        el => el.nodeName === 'IMG'
      )

      if (imageElement) {
        imageElement.addEventListener('load', () => {
          setTimeout(() => el.classList.add('loaded'), 100)
        })
        imageElement.addEventListener('error', (err) => console.log('error', err))
        imageElement.src = imageElement.dataset.url
      }
    }

    function handleIntersect(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          loadImage()
          observer.unobserve(el)
        }
      });
    }

    function createObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      };
      const observer = new IntersectionObserver(handleIntersect, options)
      observer.observe(el)
    }
    if (window['IntersectionObserver']) {
      createObserver()
    } else {
      loadImage()
    }
  }
};
