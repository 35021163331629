import request from '../../components/virtualfair/request'
import config from '../../components/virtualfair/config'

import {
  SET_ZOOM,
  SET_ZOOM_META,
  SET_BOOTHS,
  SET_SECTIONS,
  SET_PAVILIONS,
  SET_MINIMAP,
} from './geodata'

// getter names
export const FAIRS = 'FAIRS/FAIRS'
export const SELECTED = 'FAIRS/SELECTED'

// action names
export const FETCH_ALL_VIRTUAL_FAIRS = 'FAIRS/FETCH_ALL_VIRTUAL_FAIRS'
export const FETCH_GEOJSON = 'FAIRS/FETCH_GEOJSON'
export const SET_SELECTED_FAIR_BY_SLUG = 'FAIRS/SET_SELECTED_FAIR_BY_SLUG'

// mutation names
export const SET_FAIRS = 'FAIRS/SET_FAIRS'
export const SET_SELECTED = 'FAIRS/SET_SELECTED'

const state = {
  selected: {},
  fairs: []
}

const getters = {
  [SELECTED]: state => state.selected,
  [FAIRS]: state => state.fairs
}

const actions = {
  async [FETCH_ALL_VIRTUAL_FAIRS] ({ commit }) {
    try {
      const fairs = await request(`${config.baseUrl}/virtualfair`)
      commit(SET_FAIRS, fairs)
      commit(SET_SELECTED, fairs[0])
      commit(SET_ZOOM, fairs[0].zoom.default, { root: true })
      commit(SET_ZOOM_META, fairs[0].zoom, { root: true })
    } catch (err) {
      // TODO: HANDLE ERROR
    }
  },

  async [FETCH_GEOJSON] ({ commit, getters }) {
    const selected = getters[SELECTED]
    try {
      await Promise.all([
        request(`${config.baseUrl}/virtualfair/${selected.id}/booths`),
        request(`${config.baseUrl}/virtualfair/${selected.id}/sections`),
        request(`${config.baseUrl}/virtualfair/${selected.id}/pavilions`),
        request(`${config.baseUrl}/virtualfair/${selected.id}/minimap`)
      ]).then(results => {
        commit(SET_BOOTHS, results[0].booths, { root: true })
        commit(SET_SECTIONS, results[1].sections, { root: true })
        commit(SET_PAVILIONS, results[2].pavilions, { root: true })
        commit(SET_MINIMAP, results[3].minimap, { root: true })
      })
    } catch (err) {
      // TODO: HANDLE ERROR
    }
  },

  [SET_SELECTED_FAIR_BY_SLUG] ({ commit, state }, slug) {
    state.fairs.map(f => {
      if (f.slug === slug) {
        commit(SET_SELECTED, f)
        commit(SET_ZOOM, f.zoom.default, { root: true })
        commit(SET_ZOOM_META, f.zoom, { root: true })
      }
    })
  }
}

const mutations = {
  [SET_FAIRS] (state, fairs) {
    state.fairs = fairs
  },

  [SET_SELECTED] (state, selected) {
    state.selected = selected
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
