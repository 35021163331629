<template>
  <ul>
    <li v-for="group in groups" :key="`${group}-nav`">
      <a :href="`#${group}`">{{ group }}</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'NavAlphabet',
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style scoped>
ul {
  padding: 0;
  margin: 0;
}

li {
  display: inline-block;
  vertical-align: top;
}

a {
  width: 30px;
  padding: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  text-align: center;
  background-color: #008bc6;
  color: white;
  font-weight: 500;
  margin: 0 4px 4px 0;
}

a:hover {
  background-color: #00a0da;
}
</style>
