<template>
  <ul>
    <li v-for="group in groups" :key="`${group}-nav`">
      <a :href="`#${group}`">{{ group }}</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'NavCategory',
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style scoped>
ul {
  padding: 0;
}

li {
  margin-bottom: 3px;
}

li:last-child {
  margin-bottom: 0;
}

a {
  font-weight: 500;
  line-height: 1.1rem;
}
</style>
