export const getBaseUrl = () => {
  if (window.env && window.env.apiV1Url) {
    return window.env.apiV1Url
  }
  return 'https://api.tradex-services.com'
}

export const getBaseAPIV2FloorplanUrl = () => {
  if (window.env && window.env.apiV2Url) {
    return window.env.apiV2Url + '/floor-plan'
  }
  return 'https://www.tradex-services.com/api/v2/floor-plan'
}