<template>
  <div>
    <div v-if="loading" class="text-center max-width-wrapper">
      <div class="loading-indicator"></div>
    </div>

    <form v-if="!loading" @submit="handleFormSubmit" class="event-registration-form">
      <input type="hidden" name="event" :value="eventId">

      <div v-if="isAuthenticated">
        <input type="hidden" name="company" :value="companyId">

        <div class="max-width-wrapper">
          <h2 style="margin-top: 20px;">Show Guide Contact Person</h2>
          <div class="section-block">
            <select name="show_guide_contact" required class="form-control">
              <option value="" selected="selected">Choose a contact person</option>
              <option v-for="contactPerson in contactPersons" :key="contactPerson.key" :value="contactPerson.key">
                {{ contactPerson.value }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="event-registration-grid">
        <div>
          <h2>Choose a possible stand option</h2>
          <div>
            <input name="id_setup" type="hidden" :value="selectedSetup ? selectedSetup.id : 0">

            <!-- Only show setups tabs if there's more than one option to select -->
            <div v-if="setups.length > 1">
              <div
                v-for="(setup) in setups"
                :key="setup.setup.label"
                @click="selectSetup(setup)"
                :class="selectedSetup && selectedSetup.id === setup.id ? 'setup-option-trigger selected' : 'setup-option-trigger'">
                {{ setup.setup.label }}
              </div>
            </div>

          </div>

          <div v-if="selectedSetup && selectedSetup.space_info" class="section-block">
            <div v-html="selectedSetup.space_info"></div>

            <modal v-if="selectedSetup.info_img || selectedSetup.info_file">
              <button slot="modal-trigger" class="btn btn-primary btn-sm">
                View Specification of Services
              </button>
              <div slot="modal-content">
                <img v-if="selectedSetup.info_img" :src="selectedSetup.info_img" class="info-image">
                <a v-if="selectedSetup.info_file" class="btn btn-primary btn-sm mt-2" :href="selectedSetup.info_file" target="_blank" download>
                  Download Specification
                </a>
              </div>
            </modal>
          </div>

          <div v-if="selectedSetup && selectedSetup.co_exhibitor_possible" class="section-block">
            <div class="label mb-1">{{ selectedSetup.co_exhibitor_radio_label }}</div>
            <div>
              <div class="label">Select whether you want to exhibit together with another company</div>
              <div><i style="font-size: small;">(If you choose "Yes" you will be able to register the Co-Exhibitor later)</i></div>
              <label for="co-ex-yes" style="margin-right: 30px; margin-top: 15px;">
                <input v-model="hasCoExhibitor" id="co-ex-yes" name="co_ex" type="radio" :value="true">
                Yes, I want a Co-Exhibitor
              </label>
              <label for="co-ex-no">
                <input v-model="hasCoExhibitor" id="co-ex-no" name="co_ex" type="radio" :value="false" checked>
                No, I don't want a Co-Exhibitor
              </label>
            </div>
            <div>
              {{ selectedSetup.co_exhibitor_price_info }}
            </div>
          </div>
          <input v-else name="co_ex" type="hidden" value="0">

          <div class="section-block">
            <div v-if="selectedSetup && selectedSetup.price_per_unit && selectedSetup.setup && selectedSetup.setup.space_options" class="mb-3">
              <div class="label">Choose space size</div>
              <select class="form-control" v-model="selectedSpace" name="space">
                <option disabled value="">Choose a value</option>
                <option v-for="option in spaceOptions" :value="option" :key="'space' + option">
                  {{ option }} m&#178;
                </option>
              </select>
            </div>

            <input v-if="selectedSetup && selectedSetup.price_flat" type="hidden" name="stand_option" :value="selectedSetup.booth_options[0].id">
            <div v-if="selectedSetup && selectedSetup.price_per_unit && selectedSetup.booth_options" class="mb-3">
              <div class="label">Choose stand shape</div>
              <select v-model="standOptionId" class="form-control" name="stand_option">
                <option disabled value="0">Choose a stand shape</option>
                <option v-for="option in selectedSetup.booth_options" :value="option.id" :key="'space' + option.id">
                  {{ option.stand_shape.label }} (min. size: {{Math.max(option.stand_shape.min_size, Math.min.apply(Math, selectedSetup.setup.space_options))}} m&#178;)
                </option>
              </select>
            </div>

            <input name="exhibition_goods" class="form-control mb-3" maxlength="512" type="text" placeholder="Description of exhibition goods">
            <input name="preferred_location" class="form-control mb-3" maxlength="256" type="text" placeholder="Preferred location (limited availability)">

            <div v-if="hallPlanUrl.length > 0">
              <a :href="hallPlanUrl" download>Product Category Hall Plan</a>
            </div>
          </div>

          <div v-if="productCategories && productCategories.length > 0" class="product-categories-wrapper section-block">
            <div>
              <h2>Product Categories</h2>
              <input type="hidden" name="category_ids" :value="selectedProductCategory ? selectedProductCategory.id : 0" />
              <div
                v-for="productCategory in productCategories"
                :key="productCategory.id"
                @click="selectProductCategory(productCategory)"
                :class="selectedProductCategory && selectedProductCategory.id === productCategory.id ? 'product-category-trigger selected' : 'product-category-trigger'">
                {{ productCategory.name }}
              </div>
            </div>
            <div>
              <h2>Details</h2>
              <ul v-if="selectedProductCategory && selectedProductCategory.children" class="detail-list">
                <li v-for="productCategory2 in selectedProductCategory.children" :key="productCategory2.name">
                  {{ productCategory2.name }}
                  <ul v-if="productCategory2.children">
                    <li v-for="productCategory3 in productCategory2.children" :key="productCategory3.name">
                      {{ productCategory3.name }}
                    </li>
                  </ul>
                </li>
              </ul>
              <div v-else>
                Select a product category. More detailed subcategories will be displayed here on selection.
              </div>
            </div>
          </div>
        </div>

        <div>
          <div v-if="selectedSetup && selectedSetup.id" class="section-block sticky-price">
            <h2>Price</h2>

            <div v-if="calculating" class="text-center">
              <div class="loading-indicator"></div>
            </div>

            <div v-if="price !== null && !calculating">
              <div v-if="price.setup" class="price-item-row">
                <div>{{ price.setup.label }}</div>
                <div>${{ price.setup.total }}</div>
              </div>

              <div v-for="fee in price.fees" :key="fee.name" class="price-item-row">
                <div v-if="parseFloat(fee.amount) > 0">{{ fee.label }}</div>
                <div v-if="parseFloat(fee.amount) > 0">${{ fee.amount }}</div>
              </div>

              <div v-if="price.optional && price.optional.booth && price.optional.booth.total" class="price-item-row">
                <div>Corner Fee</div>
                <div>${{ price.optional.booth.total }}</div>
              </div>

              <div v-if="price.external_fees && price.external_fees.length > 0" class="price-item-row">
                <div>External Fee: (Directly Charged by Informa)</div>
                <div>${{ price.external_fees[0] }}</div>
              </div>

              <div v-if="price.total !== undefined" class="price-item-row">
                <div><b>Summe / Total</b></div>
                <div><b>${{ price.total }}</b></div>
              </div>
              <div v-if="price.price_per_sqm" class="price-item-row">
                <div>Price per sqm</div>
                <div>${{ price.price_per_sqm }}</div>
              </div>
              <div v-if="price.external_fees && price.external_fees.length > 0" style="text-align: right;">
                <i style="font-size: small;">Total excluding external fees</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="offerTradexPlus" class="tradex-plus-membership max-width-wrapper">
        <img :src="tradexPlusIconUrl" alt="Tradex+ Logo" class="mb-3">
        <div class="mb-2">The best trade fair experience</div>
        <div>
          <label class="mb-0" for="tradex_plus">
            <input class="checkbox" id="tradex_plus" name="tradex_membership" value="Ja" type="checkbox">
            Book a Tradex+ Membership. Get special discounts and access to exclusive services.
          </label>
        </div>
        <div class="py-2">449 € / year</div>
        <a title="Tradex+ Info" :href="tradexPlusUrl" target="_blank">See details</a>
      </div>

      <div v-if="isAuthenticated && isFullUser" class="pb-3 pl-3 max-width-wrapper">
        <div>
          <label for="id-legal-checkbox">
            <input type="checkbox" name="legal_checkbox" id="id-legal-checkbox" required>
            We accept the <a :href="termsUrl" download>Terms and Conditions of Participation</a>
          </label>
        </div>

        <div>
          <label for="id-gdpr-checkbox">
            <input type="checkbox" name="gdpr_checkbox" id="id-gdpr-checkbox" required>
            We accept and have read the <a :href="privacyUrl" target="_blank">Data Protection Declaration</a>
          </label>
        </div>

        <div v-if="selectedSetup && selectedSetup.info_file">
          <label for="id-specification-checkbox">
            <input type="checkbox" name="specification_checkbox" id="id-specification-checkbox" required>
            We have read the and accept <a :href="selectedSetup.info_file" target="_blank" download>Specification of Services</a> for the selected setup
          </label>
        </div>

        <div class="pt-2">
          <input type="hidden" name="status" :value="status">
          <button type="submit" class="btn btn-primary" style="max-width: 100%;">
            Submit binding registration for {{ eventName }}
          </button>
        </div>

        <div v-if="errorMessage !== null" class="mt-3">
          <alert type="alert-danger">
            {{ errorMessage }}
          </alert>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'event-registration-form',
  props: {
    token: { type: String, required: true },
    requestUrl: { type: String, required: true },
    companyId: { type: String, required: true },
    eventId: { type: String, required: true },
    eventName: { type: String, required: true },
    eventUrl: { type: String, required: true },
    status: { type: String, required: true },
    isAuthenticated: { type: Boolean, required: true },
    isFullUser: { type: Boolean, required: true },
    hasTradexPlus: { type: Boolean, required: true },
    tradexPlusIconUrl: { type: String, required: true },
    tradexPlusUrl: { type: String, required: true },
    termsUrl: { type: String, required: true },
    privacyUrl: { type: String, required: true },
    successRedirectUrl: { type: String, required: true },
    contactPersons: { type: Array, required: true },
  },
  data () {
    return {
      loading: false,
      errorMessage: null,
      registrationData: null,
      setups: [],
      selectedSetup: null,
      productCategories: [],
      selectedProductCategory: null,
      downloads: [],
      price: null,
      spaceOptions: null,
      offerTradexPlus: null,
      // v-models
      selectedSpace: null,
      hasCoExhibitor: false,
      standOptionId: null,
      responsesCounter: 0,
      priceUpdateSession: {},
    }
  },
  computed: {
    hallPlanUrl: function () {
      if (this.downloads.length > 0) {
        const hallPlanDownload = this.downloads.find(d => d.download_type.name === 'Product Category Hall Plan')
        return hallPlanDownload ? hallPlanDownload.file : ''
      }
      return ''
    },
    calculating: function (){
      return this.responsesCounter > 0
    }
  },
  methods: {
    async fetchRegistrationData () {
      try {
        const res = await fetch(`${this.eventUrl}registration`)
        this.registrationData = await res.json()

        // Set active setups
        if (this.registrationData && this.registrationData.setups && this.registrationData.setups.length > 0) {
          this.setups = this.registrationData.setups
            .filter((setup) => setup.active === true)
            .sort((s1, s2) => s1.ordering - s2.ordering);

          // Set initial setup
          if (this.setups && this.setups[0]) {
            this.selectedSetup = this.setups[0]
          }
        }
        // Set downloads
        if (this.registrationData && this.registrationData.downloads) {
          this.downloads = this.registrationData.downloads
        }
        this.offerTradexPlus = !this.registrationData.includes_vf_plus && !this.hasTradexPlus

      } catch (err) {
        console.error('Unable to fetch registration data', err)
      }
    },
    async fetchProductCategories () {
      try {
        const res = await fetch(`${this.eventUrl}productcategories`)
        const productCategories = await res.json()
        if (productCategories && productCategories.product_categories) {
          this.productCategories = productCategories.product_categories

          // Set initial product category
          if (this.productCategories && this.productCategories[0]) {
            this.selectedProductCategory = this.productCategories[0]
          }
        }
      } catch (err) {
        console.error('Unable to fetch product categories', err)
      }
    },
    async calculatePrice (e) {
      // This function is called on button click and also watched (which doesn't have an attached event)
      if (e) e.preventDefault()
      const currentSession = {} // create shallow object to have a referential identity to compare to
      this.priceUpdateSession = currentSession
      this.responsesCounter += 1
      try {
        let data = {}

        if (this.standOptionId !== null) data.stand_option_id = this.standOptionId
        if (this.hasCoExhibitor !== undefined) data.include_co_exhibitor = this.hasCoExhibitor
        if (this.selectedSpace !== null) data.space = this.selectedSpace

        const res = await fetch(`${this.eventUrl}event-price/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': this.token
          },
          credentials: 'include',
          body: JSON.stringify(data)
        })
        if (res.status === 200 && this.priceUpdateSession === currentSession) {
          this.responsesCounter = 0
          this.price = await res.json()
        }
        else{
        console.error("Error fetching price data")
        }
      } catch (err) {
        console.error('Unable to calculate price', err)
      } finally {
        this.responsesCounter > 0 ? this.responsesCounter -= 1 : this.responsesCounter = 0
      }
    },
    selectSetup (setup) {
      this.selectedSetup = setup
    },
    selectProductCategory (productCategory) {
      this.selectedProductCategory = productCategory
    },
    async handleFormSubmit (e) {
      e.preventDefault()

      const {
        event, company, show_guide_contact, id_setup, space, co_ex, stand_option, exhibition_goods,
        preferred_location, category_ids, tradex_membership, legal_checkbox, gdpr_checkbox
      } = e.target.elements

      let data = {}

      if (event && event.value !== undefined) data.event = event.value
      if (company && company.value !== undefined) data.company = company.value
      if (show_guide_contact && show_guide_contact.value !== undefined) data.show_guide_contact = show_guide_contact.value
      if (id_setup && id_setup.value !== undefined) data.id_setup = id_setup.value
      if (space && space.value !== undefined) data.space = space.value
      if (co_ex && co_ex.value !== undefined) data.co_ex = co_ex.value
      if (stand_option && stand_option.value !== undefined) data.stand_option = stand_option.value
      if (exhibition_goods && exhibition_goods.value !== undefined) data.exhibition_goods = exhibition_goods.value
      if (preferred_location && preferred_location.value !== undefined) data.preferred_location = preferred_location.value
      if (category_ids && category_ids.value !== undefined) data.category_ids = category_ids.value
      if (tradex_membership) data.tradex_membership = tradex_membership.checked
      if (legal_checkbox) data.legal_checkbox = legal_checkbox.checked
      if (gdpr_checkbox) data.gdpr_checkbox = gdpr_checkbox.checked

      try {
        const res = await fetch(`${this.requestUrl}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': this.token,
          },
          credentials: 'include',
          body: JSON.stringify(data),
        })
        if (res.status === 200) {
          this.errorMessage = null
          if (this.successRedirectUrl) {
            window.location.replace(this.successRedirectUrl)
          }
        }

        // Show error messages, see online_manual/views/page/event_register_view.py
        else if (res.status === 400) {
          try {
            const errorText = await res.text()
            this.errorMessage = errorText
          } catch (textError) {
            console.error('Unable to parse response text', textError)
            this.errorMessage = res.statusText
          }
        }
        else{
        this.errorMessage="Something went wrong. If this error persists, pleaes contact our team directly"
        }
      } catch (err) {
        console.error('Unable to register for event', err)
      }
    }
  },
  watch: {
    selectedSetup: function (newVal, oldVal) {
      if (newVal !== oldVal || (newVal && oldVal && newVal.id !== oldVal.id)) {
        // Set default stand shape and space option for the pricing calculation to update properly
        if (
          this.selectedSetup &&
          this.selectedSetup.setup &&
          this.selectedSetup.setup.space_options &&
          this.selectedSetup.setup.space_options.length > 0
        ) {
          this.selectedSpace = this.selectedSetup.setup.space_options[0]
        }
        if (
          this.selectedSetup &&
          this.selectedSetup.co_exhibitor_possible === false
        ){
          this.hasCoExhibitor = undefined
        }

        if (
          this.selectedSetup &&
          this.selectedSetup.booth_options &&
          this.selectedSetup.booth_options.length > 0
        ) {
          this.standOptionId = this.selectedSetup.booth_options[0].id
        }

        this.calculatePrice()
      }
    },
    standOptionId: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const standOption = this.selectedSetup.booth_options.filter(bo => bo.id === this.standOptionId)[0]
        const spaceOptions = this.selectedSetup.setup.space_options.filter(value => value >= standOption.stand_shape.min_size)
        if (!spaceOptions.includes(this.selectedSpace)) {
          this.selectedSpace = spaceOptions[0]
        }
        this.spaceOptions=spaceOptions
        this.calculatePrice()
      }

    },
    hasCoExhibitor: function (newVal, oldVal) {
      if (newVal !== oldVal) this.calculatePrice()
    },
    selectedSpace: function (newVal, oldVal) {
      if (newVal !== oldVal) this.calculatePrice()
    }
  },
  async created () {
    this.loading = true
    await this.fetchRegistrationData()
    await this.fetchProductCategories()
    this.loading = false
  }
}
</script>
